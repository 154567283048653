import { Link } from "react-router-dom";
import { useState } from "react";
import  nestor from './nestor.jpg'
import ReabateOp from "../components/SVG/RebateOp";
import SignUpForm from "../components/Forms/SignUpForm";

export default function Login() {

    const [ mouseHover, onMouseHover ] = useState(false);
    
    return (
        <>
            <div className="md:grid grid-cols-[1fr,1fr]">
                <div className="bg-primary-dark">
                    <SignUpForm />
                </div>
                <div className="hidden md:flex bg-primary-light h-[100vh]  flex-col justify-center items-center">
                    <Link to={"/"} onMouseEnter={() => onMouseHover(true)} onMouseLeave={() => onMouseHover(false)}>
                        {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?<ReabateOp switchColors={mouseHover?"#17b3d3":"#00141c"} />:
                        <img className="w-[400px] 2xl:w-[800px] rounded-bl-[45%]" src={nestor} alt="Company logo"></img>}
                        {/* <img className="w-[400px] 2xl:w-[800px] rounded-bl-[45%]" src={nestor} alt="Company logo image"></img> */}
                    </Link>
                </div>
            </div>
        </>
    )
}
import React, { useState } from "react";
import { Link } from "react-router-dom";
import  nestor from './nestor.jpg'
import ReabateOp from "../components/SVG/RebateOp";
import LoginForm from "../components/Forms/LoginForm";
import MobileBlocker from "../components/Navbar/MobileBlocker";
import SEO from "../components/SEO/SEO";

export default function Login() {
    
    const [ mouseHover, onMouseHover ] = useState(false);
    
    // useEffect(() => {
    //     if (toggler.isLoading&&account.user.userData.username) {
    //         navigate('/')
    //     }
    // }, [window.location.href, account.user.userData.username]);
    
    return (
        <>  
            <SEO title="Log into 259Rx" description="Log into 259Rx to access your data." />
            <MobileBlocker />
            <div className="grid grid-cols-[1fr,1fr]">
                <div className="bg-primary-light h-[100vh] flex flex-col justify-center items-center">
                    <Link to={"/"} onMouseEnter={() => onMouseHover(true)} onMouseLeave={() => onMouseHover(false)}>
                        {/* conditional fpr live site */}
                        {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?
                        <ReabateOp switchColors={mouseHover?"#17b3d3":"#00141c"} />:
                        <img className="w-[400px] rounded-bl-[45%]" src={nestor} aria-labelledby="Logo image of 259 nestor"></img>}
                        {/* <img className="w-[400px] rounded-bl-[45%]" src={nestor} alt="Logo image of 259 nestor"></img> */}
                    </Link>
                </div>
                <div className="bg-primary-dark">
                    <LoginForm />
                </div>
            </div>
        </>
    )
}



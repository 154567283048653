import {extractFromLocal} from "./loadSave";

export async function getPaymentFiles(set) {
    try {   
        const data = await fetch(`/api/get_pmt_files/`, {
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });
        let response = await data.json();
        if (data.ok) {
            set(data => ({
                ...data,
                ...response
            }));
        };

    } catch (err) {
        console.log(err);
    };
};

export async function getPaymentFields(set, paymentTable, claimsTable) {
    try {   
        if (paymentTable.includes("pmt_")) {
            paymentTable = paymentTable.substring(4);
        };

        const data = await fetch(`/api/get_pmt_fields/?pmt_table=${paymentTable}&claims_table=${claimsTable}`, {
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
        });
        let response = await data.json();
        if (data.ok) {
            set(data => ({
                ...data,
                pmt_columns: response.pmt_columns,
                claims_columns: response.claims_columns,
                reversed_mapping: response.reversed_mapping
            }));
        };

    } catch (err) {
        console.log(err);
    };
};

export function addRow(set) {
    set(data => ([
        ...data,
        {
            on_claims_column: "",
            on_pmt_column: "",
        },
    ]));
};

export async function matchLobDynamic(formData, joinsTable, fileNames, setStats, setLoading) {
    setLoading(true)
    try {
        let payload = {
            ...formData,
            claims_table: fileNames.claims_file,
            pmt_table: fileNames.pmt_file,
            formularyid: fileNames.formulary_rate_file,
            joins: joinsTable
        };

        const data = await fetch(`/api/match_lob_dynamic/`, {
            method: "POST",
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            },
            body: JSON.stringify(payload)
        });
        await data.json();
        if (data.ok) {
            getPaymentStatistics(formData.case_name, setStats, setLoading);
        };

    } catch (err) {
        console.log(err);
        setLoading(false);
    };
};

export async function getPaymentStatistics(casename, setStats, setLoading) {
    try {
        const data = await fetch(`/api/get_pmt_statistics/?case_name=${casename}`, {
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            },
        });
        let response = await data.json();
        if (data.ok) {
            setStats(response);
            setLoading(false);
        };

    } catch (err) {
        console.log(err);
        setLoading(false);
    };
};
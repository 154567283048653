import React from 'react';
import { formatDecimal } from '../../utils/resultsSummary';

export default function AnalyticsTopVolumeCategoriesTable({tableStyles, headerStyles, context }) {
    return (
        <>
             <table className={tableStyles}>
                <thead>
                    <tr>
                        <td className={headerStyles}>
                            category
                        </td>
                        <td className={headerStyles+" text-center"}>
                            Excluded Status
                        </td>
                        <td className={headerStyles+" text-center"}>
                            Rebated Status
                        </td>
                        <td className={headerStyles+" text-right"}>
                            Total Claims
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {context.map((row, idx) => (
                        <tr key={`Highest Product No Rebate key: ${row.drugname}, index: ${idx}`}>
                            <td>
                                {row.category}
                            </td>
                            <td className='text-center'>
                                {row.excluded_status}
                            </td>
                            <td className='text-center'>
                                {row.excluded_status}
                            </td>
                            <td className='text-right'>
                                {formatDecimal(row.total_claims)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
};
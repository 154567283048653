import React from 'react';
import PopUpSection from '../Section/PopUpSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { mergeObjects } from '../../utils/globalHandlers';

export default function ConstraintBuilder({constraintBuilder, toggleCB, stats, constrain, constraint}){
    
    const ruleBook = {
        "format cells with constraint values": "01",
        "format cells and omit constraint values": "02",
        "format averaged values": "03",
    };
    const dropDownStyle = "border py-3 px-4 w-full ";
    const buttonStyles = 'w-full bg-light-blue py-3 rounded-full mt-4 text-white font-bold hover:bg-hover-blue';

    function chooseRule(title) {
        constrain(data => ({
            ...data,
            setting: {
                ...data.setting,
                rule: title
            }
        }));
    };
    function chooseConstraints(e) {
        const { name, value } = e.target;
        constrain(data => ({
            ...data,
            setting : {
                ...data.setting,
                [name]: value
            },
        }));
    };
    function tempAddRule(e) {
        e.preventDefault();
        constrain(data => ({
            ...data,
            temp: {
                ...data.temp, 
                [constraint.setting.type]: data.temp[constraint.setting.type]?
                [
                    ...data.temp[constraint.setting.type], 
                    {...data.setting}
                ]:
                [
                    {...data.setting}
                ]
            },
            setting: {
                rule: "",
                type: "",
                choice: "",
                operator: "",
                freeform: "",
            },
        }));
    };
    function resetAll() {
        constrain(() => ({
            temp: {},
            rules: {},
            setting: {
                rule: "",
                type: "",
                choice: "",
                operator: "",
                freeform: "",
            },
        }));
    };
    function clearChoices() {
        constrain(data => ({
            ...data,
            temp: {},
            setting: {
                rule: "",
                type: "",
                choice: "",
                operator: "",
                freeform: "",
            },
        }));
    };
    function clearTemp() {
        constrain(data => ({
            ...data,
            temp: {},
        }));
    };
    function saveChoices(){
        constrain(data => ({
            ...data,
            rules: {
                ...mergeObjects(constraint.rules, constraint.temp)
            },
            temp: {}
        }));
    };
    function closeBuilder() {
        toggleCB(!constraintBuilder);
        clearChoices();
    };
    
    return (
        <>
            <PopUpSection>
                <form onSubmit={tempAddRule} className='relative min-h-[850px] w-[1100px] bg-white mx-auto rounded-lg shadow-xl px-14 py-20'>
                    <button className='absolute right-4 top-4' type='button' onClick={closeBuilder}>
                        <FontAwesomeIcon className='hover:rotate-[180deg] duration-500' icon={faXmark}/>
                    </button>
                    {stats.success?<>
                        <div className='mb-10 border p-2'>
                            <div>temp rules</div>
                            {Object.keys(constraint.temp).map(section => (
                                <ul className='flex'>
                                    <li>{section}</li>
                                    {(constraint.temp[section]).map(rules => (
                                        <li>
                                            <div className='border'>
                                                <span className='pr-3'>+</span>
                                                <span className='pr-2'>{`${rules.rule}:`}</span>
                                                <span>{rules.choice}</span>
                                                <span>{rules.operator}</span>
                                                <span>{rules.freeform}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ))}

                        </div>
                        <div className='mb-10 border p-2'>
                            <div>current rules</div>
                            {Object.keys(constraint.rules).map(section => (
                                <ul className='flex'>
                                    <li>{section}</li>
                                    {(constraint.rules[section]).map(rules => (
                                        <li>
                                            <div>
                                                <span className='pr-2'>{rules.rule}:</span>
                                                <span>{rules.choice}</span>
                                                <span>{rules.operator}</span>
                                                <span>{rules.freeform}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ))}

                        </div>
                        <ul className='border w-full min-h-[280px] ' >
                            {Object.entries(ruleBook).map(rule => (
                                (rule!=='success'&&rule!=='add_gaps'&&rule!=='message')&&
                                    <li 
                                        onClick={() => chooseRule(rule[1])} 
                                        className={`w-full border-b px-6 py-4 text-left hover:bg-primary-dark hover:text-white hover:cursor-pointer 
                                            ${rule[1]===constraint.setting.rule?"bg-primary-dark text-white":""} `}>
                                        {rule[0]}
                                    </li>
                                ))
                            }
                        </ul>
                        <div className='flex gap-3 pt-6'>
                            <select value={constraint.setting.type}  onChange={chooseConstraints} name="type" className={dropDownStyle}>
                                <option>
                                    -- choose one --
                                </option>
                                {Object.keys(stats).map((type, index) => (
                                    (type!=='success'&&type!=='add_gaps'&&type!=='message')&&
                                        <option key={`${index}: choice ${type}`} value={type}>
                                            {type}
                                        </option>
                                ))}
                            </select>
                            <select value={constraint.setting.choice}  onChange={chooseConstraints} name="choice" className={dropDownStyle}>
                                <option>
                                    -- choose one --
                                </option>
                                {stats[constraint.setting.type]&&Object.keys(stats[constraint.setting.type]?.[0]).map((colummn, index) => (
                                    <option key={`${index}: choice ${colummn}`} value={colummn}>
                                        {colummn}
                                    </option>
                                ))}
                            </select>
                            <select value={constraint.setting.operator} onChange={chooseConstraints} name='operator' className={dropDownStyle}>
                                <option>
                                    -- choose one --
                                </option>
                                {/* <option value='between'>
                                    between
                                </option>
                                <option value='not_between'>
                                    not between
                                </option> */}
                                <option name='equal_to' value="===">
                                    equal to
                                </option>
                                <option name='not_equal_to' value="!==">
                                    not equal to
                                </option>
                                <option name='greater_than' value=">">
                                    greater than
                                </option>
                                <option name='less_than' value="<">
                                    less than
                                </option>
                                <option name='greter_than_or_equal_to' value=">=">
                                    greter than or equal to
                                </option>
                                <option name='less_than_or_equal_to' value="<=">
                                    less than or equal to
                                </option>
                            </select>
                            <input value={constraint.setting.freeform} name='freeform' onChange={chooseConstraints} className='w-full border py-3 px-2 min-w-[400px] ' />
                            <button type='submit' className='bg-light-blue hover:bg-hover-blue text-white px-2 font-bold'>
                                +
                            </button>
                        </div>
                        <div className='flex gap-20'>
                            <button type='button' onClick={resetAll} className={buttonStyles}>
                                reset rules
                            </button>
                            <button type='button' onClick={clearTemp} className={buttonStyles}>
                                clear temp
                            </button>
                            <button type='button' onClick={saveChoices} className={buttonStyles}>
                                Save
                            </button>
                        </div>
                    </>:<div className='flex justify-center items-center h-full w-full'>select a data set</div>}
                </form>
            </PopUpSection>
        </>
    );
};
import { useEffect, useRef } from "react";
import Analytics from "../Analytics/Analytics";
import CrystalBallSection from "../CrystalBall/CrystalBallSection";
import Events from '../Events/Events';
import KpiPanel from "../KpiPanel/KpiPanel";
import ResultSummarySection from "../ResultsSummary/ResultSummarySection";
import StatusBox from "../StatusBox/StatusBox";
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import PricingLensPanel from "../PricingLens/PricingLensPanel";
import RatesWindow from "../Rates/RatesWindow";

export default function MainSection({aux}) {

    const mainPageRef = useRef(null);

    const account = UseUserContext();
    const toggler = UseToggleContext();

    useEffect(() => {
        if (mainPageRef&&mainPageRef.current) {
            mainPageRef.current.addEventListener("click", () => {
                toggler.switchAux("")
            })
        }
    }, [toggler]);

    return (
        <>
            <div className="" style={{width: toggler.pageWidth, height: toggler.pageHeight}}>
                <div  ref={mainPageRef} className={` grid pb-24 ${(account.user?.userData?.user_type==="admin"||account.user?.userData?.user_type==="superadmin"||account.user?.userData?.user_type==="trade_user"||account.user?.userData?.user_type==="client_mgr")?" xl:grid-cols-[1fr,1fr] pr-8 xl:pr-0":"pr-10"} h-full overflow-x-auto bg-background-light`}>
                    <KpiPanel />
                    <ResultSummarySection />
                </div>
                <StatusBox />
                <CrystalBallSection />
                {(account.user?.userData?.user_type==="admin"||account.user?.userData?.user_type==="superadmin"||account.user?.userData?.user_type==="trade_user"||account.user?.userData?.user_type==="client_mgr")&&
                    <>
                        <RatesWindow />
                        <Events />
                    </>
                }
                <Analytics />
                <PricingLensPanel />
            </div>
        </>
    )
}
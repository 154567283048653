import React, { useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { deltaArray, divideDeltaArray, sumTotalObjects, transform } from '../../utils/pricingLens';
import { globalUpdateKeyValue } from '../../utils/globalHandlers';
import { convertYears, formatCurrencyExp, sumTotals } from '../../utils/resultsSummary';
import LockedMessage from '../Error/LockedMessage';
import ClientPerformanceScenario from './ClientPerformanceScenario';
import { tailChase } from 'ldrs';

export default function PerformanceLens({reference}) {
    tailChase.register();

    const context = UseContextClues();

    const [ scenario, selectScene ] = useState("results")
    const [ performance, change ] = useState({
        "Year1":{"R30":0,"R90":0,"M":0,"S":0},
        "Year2":{"R30":0,"R90":0,"M":0,"S":0},
        "Year3":{"R30":0,"R90":0,"M":0,"S":0},
        "Year4":{"R30":0,"R90":0,"M":0,"S":0},
        "Year5":{"R30":0,"R90":0,"M":0,"S":0}
    });
    const [ clientPerformance, toggle ] = useState(false);
    const [ clientPerfData, setClientPerfData ] = useState({});
    const [ loading, setLoading ] = useState(false)
    
    const totals = JSON.parse(context?.[scenario]?.Rebates_summary);
    const claimCount = JSON.parse(context[scenario]?.Rx_summary);
    const gtee = sumTotals(transform(sumTotalObjects(claimCount, performance)));
    const delta = deltaArray(gtee, sumTotals(transform(totals)));

    const headerStyles = "font-bold pr-8 bg-primary-dark text-white border pl-2 transition-all duration-500"
    const bodyStyles = " py-3 text-right px-2 ";


    function sumTotalObject(obj1, obj2) {
        var sumObj = {};
        Object.keys(obj1).forEach(channel => {
            Object.keys(obj1[channel]).forEach(year => {
                if (sumObj[year]===undefined) {
                    sumObj[year] = obj1?.[channel]?.[year] * obj2?.[channel]?.[year]
                } else {
                    sumObj[year] += (obj1?.[channel]?.[year] * obj2?.[channel]?.[year])
                };
            });
        });
        return sumObj
    };

    return (
        <div ref={reference}>
            <div className='pb-8 flex items-center gap-8 justify-between'>
                <label>
                    <span className='font-medium'>Client Performance Scenario</span>
                    <select className='ml-3 uppercase font-medium border py-1 px-2' onChange={(e) => selectScene(e.target.value)}>
                        <option value="results">standard</option>
                        <option value="target">target</option>
                    </select>
                </label>
                <button onClick={() => toggle(!clientPerformance)} className='shadow border py-1 px-3 rounded-full font-medium bg-light-blue hover:bg-hover-blue hover:shadow-xl text-white'>
                    Get Contract Guarantees
                </button>
            </div>
            <LockedMessage />
            <table className='bg-background-light'>
                <thead>
                    <tr className='bg-primary-dark text-white'>
                        <th className={`text-left py-2 pl-2 ${Object.keys(clientPerfData).length>0?"pb-10":''}`}>Channel</th>
                        {Object.keys(JSON.parse(context[scenario].Rebates_summary)).map((years, idx) => (
                            ((context.exclusionSettings.dealLength>=idx+1&&Object.keys(clientPerfData).length===0)||clientPerfData['total_deal_length_years']>=idx+1)&&<th key={`${idx} - Channel Year: ${years}`} className={`border whitespace-pre-wrap h-12 w-[160px] py-1 px-2 text-sm ${Object.keys(clientPerfData).length>0?"pb-10":''} bg-primary-dark text-white  my-2 text-center`} >
                                {convertYears(years, context.settings.year.year1)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(clientPerfData).length>0?<tr className='relative '>
                        <td className='absolute z-[10] flex justify-center bottom-[0px] left-0 right-0 border-t h-[30px] bg-primary-dark w-full text-white'>
                            Rates
                        </td>
                    </tr>:null}
                    {Object.keys(transform(performance)).map((channel, idx) => (
                        <tr key={`Performance Lens Numbers Table No.: ${idx}`}>
                            <td className={headerStyles+` ${(Object.keys(clientPerfData).length>0&&channel==="S")?"pb-20":""}`}>
                                {channel}
                            </td>
                            {Object.entries(transform(performance)[channel]).map((year, index) => (
                                ((Object.keys(clientPerfData).length===0&&context.exclusionSettings.dealLength>=index+1)||(Object.keys(clientPerfData).length>=1&&clientPerfData['total_deal_length_years']>=index+1))&&<td key={`${index} performance lens input by year: ${year}`} className={`transition-all duration-500 ${(Object.keys(clientPerfData).length>0&&channel==="S")?"pb-20":""}`}>
                                    {Object.keys(clientPerfData).length>0?<>&nbsp;&nbsp;&nbsp;</>:<span className='pl-2'>
                                        $
                                    </span>}
                                    <input 
                                        className='px-2 p-1 my-1 border-4 w-[120px]  mt-2'
                                        type='number' 
                                        min={0} 
                                        step={1} 
                                        autoFocus
                                        value={Object.keys(clientPerfData)?.length>2?clientPerfData?.['rates_by_channel_and_year']?.[channel]?.[year[0]]?clientPerfData?.['rates_by_channel_and_year']?.[channel][year[0]]:0:performance?.[year[0]][channel]}
                                        onChange={e => (Object.keys(clientPerfData).length>0?globalUpdateKeyValue(year[0], e.target.value, setClientPerfData, channel, 'rates_by_channel_and_year'):globalUpdateKeyValue(channel, e.target.value, change, year[0]))} 
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                    {Object.keys(clientPerfData).length>0?<tr className='relative '>
                        <td className='absolute flex justify-center bottom-[0px] left-0 right-0 h-[30px] border-y bg-primary-dark w-full text-white'>
                            Rx Volume
                        </td>
                    </tr>:null}
                    {Object.keys(clientPerfData).length>0?
                    <>
                        {Object.keys(clientPerfData['rx_volume_by_channel_and_year']).map(channel => (
                            <tr>
                                <td className={headerStyles+` h-[54px] ${channel==="S"?"pb-20":""}`}>{channel}</td>
                                {Object.entries(clientPerfData['rx_volume_by_channel_and_year'][channel]).map((volume, idx) => (
                                    <td key={`${idx}: Client Performance Lens - Rx vol by channel `} className={` py-3 px-2 text-right ${channel==="S"?"pb-20":""}`}>
                                        {volume[1].toFixed(3)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </>
                    :null}
                    {Object.keys(clientPerfData).length>0?<tr className='relative '>
                        <td className='absolute flex justify-center bottom-[0px] left-0 border-y right-0 h-[30px] bg-primary-dark w-full text-white'>
                            Values
                        </td>
                    </tr>:null}
                    {Object.keys(clientPerfData).length>0?
                        Object.keys(clientPerfData['rx_volume_by_channel_and_year']).map(channel => (
                            <tr>
                                <td className={headerStyles+" h-[54px]"}>{channel}</td>
                                {Object.entries(clientPerfData['rx_volume_by_channel_and_year'][channel]).map((year, idx) => (
                                    <td key={`${idx}: Client Performance Lens - values `} className={" py-3 px-2 text-right"}>
                                        {(clientPerfData['rx_volume_by_channel_and_year'][channel][year[0]] * clientPerfData['rates_by_channel_and_year'][channel][year[0]]).toFixed(3)||0}
                                    </td>
                                ))}
                            </tr>
                        ))
                    :null}
                    <tr>
                        <td className={headerStyles+` pt-20 ${Object.keys(clientPerfData).length>0?"pb-4":""}`}>Gtee</td>
                        {Object.keys(clientPerfData).length>0?Object.entries(sumTotalObject(clientPerfData['rates_by_channel_and_year'], clientPerfData['rx_volume_by_channel_and_year'])).map((totals, idx) => (
                            <td key={`${idx}: Performance Lens - gtee`} className={bodyStyles+" pt-20 pb-4 "}>
                                {formatCurrencyExp(totals[1])}
                            </td>
                        )):gtee.map((totals, idx) => (
                            context.exclusionSettings.dealLength>=idx+1&&<td key={`${idx}: Performance Lens - gtee`} className={bodyStyles +" pt-20"}>
                                {formatCurrencyExp(totals)}
                            </td>
                        ))}
                    </tr>
                        <tr>
                            <td className={headerStyles}>Performance</td>
                            {sumTotals(transform(totals)).map((total, idx) => (
                                ((context.exclusionSettings.dealLength>=idx+1&&Object.keys(clientPerfData).length===0)||clientPerfData['total_deal_length_years']>=idx+1)&&<td key={`${idx}: Performance Lens - performance`} className={bodyStyles}>
                                    {formatCurrencyExp(total)}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className={headerStyles}>Delta</td>
                            {Object.keys(clientPerfData).length===0?delta.map((delta, idx) => (
                                context.exclusionSettings.dealLength>=idx+1&&<td key={`${idx}: Performance Lens - delta`} className={bodyStyles+(delta<0?"text-primary-red":"text-primary-green")}>
                                    {formatCurrencyExp(delta)}
                                </td>
                            )):sumTotals(transform(totals)).map((total, idx) => (
                            clientPerfData['total_deal_length_years']>=idx+1&&<td key={`${idx}: Performance Lens - performance`} className={bodyStyles+` ${total - sumTotalObject(clientPerfData['rates_by_channel_and_year'], clientPerfData['rx_volume_by_channel_and_year'])["Year"+(idx+1)]<0?"text-primary-red":"text-primary-green"}`}>
                                {formatCurrencyExp(total - sumTotalObject(clientPerfData['rates_by_channel_and_year'], clientPerfData['rx_volume_by_channel_and_year'])["Year"+(idx+1)])}
                            </td>))}
                        </tr>
                    {Object.keys(clientPerfData).length>0?null:<>
                        <tr>
                            <td className={headerStyles}>Percentage</td>
                            {divideDeltaArray(delta, gtee).map((percent, idx) => (
                                context.exclusionSettings.dealLength>=idx+1&&<td key={`${idx}: Performance Lens - percentage`} className={bodyStyles+(percent<0?"text-primary-red":(percent!==Infinity?"text-primary-green":"text-black"))}>
                                    {percent===Infinity?"--":(percent*100).toFixed(2)}%
                                </td>
                            ))}
                        </tr>
                    </>}
                </tbody>
            </table>
            {clientPerformance?<ClientPerformanceScenario {...{ toggle, scenario, clientPerfData, setClientPerfData, setLoading }} />:null}
            {loading&&<div className='absolute top-0 bottom-0 bg-black opacity-[0.6] right-0 left-0 w-full h-full z-[300] flex justify-center items-center'>
                <l-tail-chase
                    size={"200"}
                    speed="1.75" 
                    color="white" 
                ></l-tail-chase>
            </div>}
        </div>
    );
};


import React, {  useEffect, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCircleCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import FormButton from '../Buttons/FormButton';
import { handleInputChange, importClaims } from '../../utils/claimsLoad';
import { tailChase } from 'ldrs'
import { globalBoolHandler, globalChangeHandler } from '../../utils/globalHandlers';
import ImportGuide from '../UserGuide/ImportGuide';
import ImportPanel from './ImportPanel';
import ImportTableRow from '../Tables/ImportTableRow';
import { UseUserContext } from '../../context/UserContext';


export default function ImportClaim({setPageData, mainPageData}){

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    tailChase.register();

    const [step, nextStep] = useState(0);

    const [ file, setFile ] = useState(null);
    const [ table, setTable ] = useState([]);
    
    const [ tableName, setTableName ] = useState("");
    const [ fields, setFeilds ] = useState({});
    const [ options, setOptions ] = useState({});

    // add error handlers next
    const [ messages, setMessage ] = useState("");

    const [ loaded, setLoaded ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    
    const [ agreement, clicked ] = useState(false);
    const [ validated, validate ] = useState(false);
    const [ headersAvail, setHeadersBool ] = useState(true);

    const fileReader = new FileReader();

    const convertCsvFile = function(string) {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + headersAvail?1:0).split("\n");
        
        const arr = csvRows.map((el, index) => {
            const vals = el.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            // const vals = el.split(',');
            const obj = csvHeader.reduce((o,h,i) => {
                o[h] = vals[i]
                return o
            }, {});
            return obj
        });

        setTable(arr.slice(1,200))

    };
    const convertPsvFile = function(string) {
        const csvHeader = string.slice(0, string.indexOf("\n")).split("|");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        
        const arr = csvRows.map(el => {
            const vals = el.split('|');
            const obj = csvHeader.reduce((o,h,i) => {
                o[h] = vals[i]
                return o
            }, {});
            return obj
        });


        setTable(arr.slice(1,200))
    };

    const convertLargePsvFile = async function(string) {
        const unit8view = new Uint8Array(string)
        const parseData = Uint8Array.from(unit8view)
        const blob = new Blob([parseData])
        
        const reader = blob.stream().getReader();
        const decoder = new TextDecoder();

        reader.read().then( function process({ done, value }) {
            const str = decoder.decode( value, { stream: true } );

            const csvHeader = str.slice(0, str.indexOf("\n")).split("|");
            const csvRows = str.slice(str.indexOf("\n") + 1).split("\n");


            const arr = csvRows.map(el => {
                const vals = el.split('|');
                const obj = csvHeader.reduce((o,h,i) => {
                    o[h] = vals[i]
                    return o
                }, {});
                return obj
            });

            
            setTable(arr.slice(1,200))
        });
                    
    }; 

    const handleSubmit = function(e) {

        e.preventDefault();
        setMessage([]);
     
        importClaims(tableName.split(" ").join("_"), fields, file, setMessage, setLoaded, setLoading, options, validate, headersAvail, 'claims', setPageData, account.user.userData.username, mainPageData, context.setModels)
    };
    
    function loadSetup(e){
        e.preventDefault();
    
        if (file) {
            fileReader.onload = function(e) {
                const text = e.target.result;
                if (file.type.includes("csv")) {
                    convertCsvFile(text)
                } else {
                    if (file.size > 500000) {
                        convertLargePsvFile(text)
                    } else {
                        convertPsvFile(text)
                    }
                }
            };
            if (file.type.includes("csv")) {
                fileReader.readAsText(file)
            } else {
                if (file.size > 500000) {
                    fileReader.readAsArrayBuffer(file)
                    
                } else {
                    fileReader.readAsText(file)
                }
            }
        };

    };

    function handleMessage(e) {
        e.preventDefault()
        const noTable = 'Please make sure a table name is provided'
        const noHeaders = "Please map all the necessary column headers below"

        const table = handleMessageHelper(!tableName, noTable)
        const names = handleMessageHelper(Object.keys(fields).length!==6, noHeaders)

        return [table, names]
    };

    function updateTableName(e) {
        let typed = e.target.value;

        if ( !typed.match("^[\b]") !== null) {
            setTableName(typed);
        };
        if (/^[a-zA-Z][a-zA-Z0-9_ ]*$/.test(typed) ) {
            setTableName(typed);
            setMessage([]);
        } else {
            setMessage(['Table Names must only include alphanumeric characters, underscores, and cannot start with a number!']);
        };
        if (!typed) setMessage([]);
    };

    function handleMessageHelper(what, note) {
        if (what) {
            if (!messages.includes(note)) {
                setMessage([
                    ...messages,
                    note
                ])
            }
        } else {
            if (messages.includes(note)) {
                setMessage(messages.filter(mess => mess !== note))
            };
        };
    };

    // var check;

    // useEffect(() => {
    //     check = function () {
    //         return Object.entries(fields).reduce(field => {
    //             return field
    //         });
    //     }
    // }, [fields])

    const headerKeys = Object.keys(Object.assign({}, ...table));

    function handleClickAgreement(){
        clicked(!agreement);
    };

    useEffect(() => {
        setMessage([]);
    }, [step]);

    const selectStyles = "px-2 py-2  border-none rounded-md w-full ";
    const tableHeadStyles = "border px-2 py-1 bg-primary-dark text-white";
    const tableBodyStyles = "border px-2 text-sm";

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false)
            }
        };
        window.addEventListener('keydown', handleEsc);
     
        return () => {
           window.removeEventListener('keydown', handleEsc);
        };
    }, [toggler.toggle, toggler.setToggle]);

    function handleAgreeNextStep(e) {
        nextStep(step+1);
        loadSetup(e);
    };

    function handleLoadTable(e) {
        handleInputChange(e, context);
        globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false);
    };

    return (

    <>
    <div className='absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-100 opacity-[1.0]'></div>
    <div className='absolute top-[0%] z-[200] w-full overflow-y-auto'>
        
        <form style={{height: toggler.pageHeight-30}} className={`relative ${step === 0?"translate-y-[0%]":"translate-y-[0%]"} max-w-4xl mx-auto justify-center`} onSubmit={handleSubmit}>
            <div className=' mx-auto bg-white opacity-1 overflow-y-auto py-6 rounded-md h-full' >
                <div className='text-xl font-medium text-center'>Claims Import Wizard</div>
                {step === 0 ? <div className=''>
                    <div className='p-4 flex justify-around items-center text-sm border-b-2'>
                        <div className='flex items-center'>
                            {!file?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                            <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                            upload file
                        </div>
                        <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                        <div className='flex items-center'>
                            {Object.keys(fields).length!==6?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                            <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                            column mapping
                        </div>
                        <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                        <div className='flex items-center'>
                            {((Object.keys(fields).length===6)&&tableName)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                            <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                            Ready to load
                        </div>
                        <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                        <div className='flex items-center'>
                            {((Object.keys(fields).length===6)&&loaded)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                            <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                            File Loaded
                        </div>
                    </div>
                    <div className='px-6 pb-20'>

                        <div className='text-center font-medium text-sm mt-6'>STEP 1 OF 3</div>
                        <div className='text-center font-bold mt-3 mb-6 text-lg'>Choose a file</div>
                        <div className="text-center h-12 font-medium py-1 text-sm flex items-center justify-center ">
                        {messages.length > 0&&
                            <div className=''>
                                {messages.map(message => (
                                    <div className={message.includes('next')?"text-primary-green":`${message.includes('moment')?"text-orange-400 w-[600px]":"text-primary-red"}`}>
                                        <FontAwesomeIcon className={`text-lg mr-4 ${message.includes('next')&&"hidden"}`} icon={faTriangleExclamation}/>
                                        {message}
                                    </div>
                                ))}
                            </div>
                        }
                        </div>
                        <ImportPanel {...{file, setFile, setMessage}} />
                        <div className='pt-8 pb-3 flex items-center'>
                            <input className='mr-4 w-[16px] h-[16px]'  type='checkbox' onChange={handleClickAgreement} checked={agreement} />
                            <span className='text-sm '>“I certify that the attached data file does not contain any <b>Protected Health Information (PHI)</b>.“</span>
                        </div>
                        <ImportGuide />

                    </div>
                    <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 bg-white'>
                        <button className='border mt-4 px-6 py-2 rounded-md border-light-blue text-light-blue font-medium hover:bg-light-blue hover:text-white' onClick={() => globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false)}>cancel</button>
                        {agreement&&<button className=' disabled:cursor-not-allowed border float-right mt-4 px-6 py-2 rounded-md bg-light-blue text-white font-medium hover:bg-hover-blue' onClick={handleAgreeNextStep} disabled={!file ? true : false}>next step</button>}
                    </div>
                </div>:
                step === 1 ? <div className='pb-20'>
                <div className='p-4 flex justify-around items-center text-sm border-b-2'>
                    <div className='flex items-center'>
                        {!file?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                        <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                        upload file
                    </div>
                    <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                    <div className='flex items-center'>
                        {Object.keys(fields).length!==6?<FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>:
                        <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>}
                        column mapping
                    </div>
                    <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                    <div className='flex items-center'>
                        {((Object.entries(fields).length===6)&&tableName)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                        <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                        Ready to load
                    </div>
                    <FontAwesomeIcon className='text-xs' icon={faChevronRight}/>
                    <div className='flex items-center'>
                        {((Object.keys(fields).length===6)&&loaded)?<FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/>:
                        <FontAwesomeIcon className='text-lg mr-4' icon={faCircle}/>}
                        File Loaded
                    </div>
                </div>
                <div className='px-6'>
                    <div className="text-center font-medium text-sm mt-6">STEP 2 OF 3</div>
                    <div className="text-center font-bold my-3 text-lg">Map Header Columns</div>
                    <div className="text-center font-light mt-3 mb-2 text-sm">Confirm the mapping of core item attributes with the columns in your import file and add a title to your <span className='underline underline-offset-2'>Table Name</span> </div>
                    <div className="text-center h-12 font-medium py-1 text-sm flex items-center justify-center ">
                        {messages.length > 0&&
                            <div className=''>
                                {messages.map(message => (
                                    <div className={message.includes('next')?"text-primary-green":`${message.includes('moment')?"text-orange-400 w-[600px]":"text-primary-red"}`}>
                                        <FontAwesomeIcon className={`text-lg mr-4 ${message.includes('next')&&"hidden"}`} icon={faTriangleExclamation}/>
                                        {message}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className='py-2 flex '>
                        <label htmlFor='tableNameInput' className='w-full'> Table Name
                            <input name='tableNameInput' className="border-2 w-full rounded-md px-3 py-2" pattern="[a-zA-Z0-9 ]+" placeholder='Add a table name..' value={tableName} onInput={updateTableName} aria-label='Name your table here.'/>
                        </label>
                        <label htmlFor='tableHeaders' className='py-1 pl-2' >Headers available?
                            <span onClick={() => setHeadersBool(!headersAvail)} className='text-lg font-extrabold  h-full cursor-pointer '>
                                <input name='tableHeaders' className="h-0 w-0 opacity-0 " type='checkbox' />
                                <span className={headersAvail?"text-light-blue":"text-primary-dark"}>Yes</span> / <span className={headersAvail?"text-primary-dark":"text-light-blue"}>No</span>
                            </span>
                        </label>
                    </div>
                    <table className='w-full mt-2'>
                            <thead>
                                <tr className='text-left'>
                                    <th className={tableHeadStyles}>Core Item Attribute</th>
                                    <th className={tableHeadStyles}>Columns in Uploaded Document</th>
                                    <th className={tableHeadStyles}>Sample Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ImportTableRow keyNumber={1} rowTitle={'Carrier'} name={'carrier'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["carrier"]} included={["id"]} optional={false} options={table[0]?.[fields['carrier']]} headerKeys={headerKeys} unavailable={true}/>
                                <ImportTableRow keyNumber={2} rowTitle={'NDC'} name={'ndc'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["ndc"]} included={["id","ndc"]} optional={false} options={table[0]?.[fields['ndc']]} headerKeys={headerKeys}/>
                                <ImportTableRow keyNumber={3} rowTitle={'QTY'} name={'qty'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["qty"]} included={["quantity","qty","supply"]} optional={false} options={table[0]?.[fields['qty']]} headerKeys={headerKeys}/>
                                <ImportTableRow keyNumber={4} rowTitle={'Days Supply'} name={'days_supply'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["days_supply"]} included={["days"]} optional={false} options={table[0]?.[fields['days_supply']]} headerKeys={headerKeys}/>
                                <ImportTableRow keyNumber={5} rowTitle={'PharmacyID'} name={'pharmacy_id'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["pharmacy_id"]} included={["id","nabp","npi"]} optional={false} options={table[0]?.[fields['pharmacy_id']]} headerKeys={headerKeys}/>
                                <ImportTableRow keyNumber={6} rowTitle={'Net Claims'} name={'net_claims'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, fields, setFeilds)} value={fields["net_claims"]} included={["contract","service","cnt","wac"]} optional={false} options={table[0]?.[fields['net_claims']]} headerKeys={headerKeys} unavailable={true}/>
                                <ImportTableRow keyNumber={7} rowTitle={'Channel'} name={'channel'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["channel"]} included={["channel","mail","retail","indicator","cd","code"]} optional={true} options={table[0]?.[options['channel']]} headerKeys={headerKeys}/>
                                <ImportTableRow keyNumber={8} rowTitle={'Compound Indicator'} name={'compound_indicator'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["compound_indicator"]} included={["claim"]} optional={true} options={table[0]?.[options['compound_indicator']]} headerKeys={headerKeys} unavailable={true}/>
                                <ImportTableRow keyNumber={9} rowTitle={'DAW Code'} name={'daw_code'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["daw_code"]} included={["claim"]} optional={true} options={table[0]?.[options['daw_code']]} headerKeys={headerKeys} unavailable={true}/>
                                <ImportTableRow keyNumber={10} rowTitle={'Date of Fill'} name={'date_of_fill'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["date_of_fill"]} included={["date"]} optional={true} options={table[0]?.[options['date_of_fill']]} headerKeys={headerKeys}/>
                                <ImportTableRow keyNumber={11} rowTitle={'Claim Status'} name={'claim_status'} tableBodyStyles={tableBodyStyles} selectStyles={selectStyles} onchange={e => globalChangeHandler(e, options, setOptions)} value={fields["claim_status"]} included={["claim"]} optional={true} options={table[0]?.[options['claim_status']]} headerKeys={headerKeys}/>
                            </tbody>
                    </table>
                </div>
                <div className='px-6 mt-6'>
                    {
                        !tableName||Object.entries(fields).length!==6 ? <FormButton buttonText={'load'} onClick={handleMessage} typeB={true} textSize={"text-sm"} addClass={'cursor-not-allowed'}/>:
                        <FormButton buttonText={'load'} loading={loading} loadingSize={15} textSize={"text-sm"}/>
                    }
                </div>
                <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 bg-white pt-4'>
                    <button className='border  px-6 py-2 rounded-md border-light-blue text-light-blue font-medium hover:bg-light-blue hover:text-white' onClick={() => globalBoolHandler("importWindow", toggler.toggle, toggler.setToggle, false)}>cancel</button>
                    <div className='float-right'>
                        <button className='border rounded-md px-6 py-2 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(step-1)}>prev step</button>
                        {validated&&tableName&&Object.keys(fields).length===6&&loaded&&<button className='border rounded-md px-6 py-2 ml-3 bg-light-blue text-white font-medium hover:bg-hover-blue' onClick={() => nextStep(step+1)}>next step</button>}
                    </div>
                </div>
                </div>:
                step === 2 ? <>
                <div className='px-6'>
                    <div className="text-center mt-20 font-medium text-sm">STEP 3 OF 3</div>
                    <div className="text-center font-bold my-6 text-xl">Check column values</div>
                    <div className="text-center font-light my-6 text-sm">Check the consistency of your table data. Once finished, click load to initiate the process. </div>
                    <div className='text-sm font-medium pb-2'>Tablename: <span className=''>{tableName}</span></div>
                    <div style={{height: toggler.pageHeight*0.5}} className='overflow-auto ' >
                        <table className='text-xs'>
                            <thead>
                                <tr className='border' key={"header"}>
                                        {headerKeys.map((key, idx) => (
                                            <th key={`${idx} - ${key}`} className='px-2 border py-3'>{key}</th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody className='text-center'>
                                {table.map((el,idx) => (
                                    <tr key={`${idx} table ${el.id}`}>
                                        {Object.values(el).map((val) => (
                                            <td className='border'>{val}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='absolute bottom-0 pb-4 w-full border-t-2 px-6 pt-4 bg-white'>
                    <button className='border rounded-md px-6 py-2 border-light-blue font-medium  text-light-blue hover:bg-light-blue hover:text-white' onClick={() => nextStep(step-1)}>prev step</button>
                    <button name='tName' value={tableName} className='border rounded-md px-6 py-2 bg-light-blue text-white font-medium hover:bg-hover-blue float-right' onClick={handleLoadTable}>load table</button>
                </div>
                </>:null}
            </div>
        </form>
        </div>
        </>

    )
};




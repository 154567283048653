import React, { useState, useEffect } from 'react';
import CollapsibleButton from '../Buttons/CollapsibleButton';
import FormButton from '../Buttons/FormButton';
import { displayCrystalResults } from '../../utils/crystalBall';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import SearchField from '../SearchField/SearchField';
import { autocomplete } from '../../utils/autoComplete';
import { convertDateToUTC, handleToggle } from '../../utils/globalHandlers';
import { handleInputChange } from '../../utils/claimsLoad';
import { useDebouncedCallback  } from 'use-debounce';

export default function CrystalBall() {

    const id = "crystalBall";

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ year1start, setStartYear ] = useState();
    const [ loading, setLoading ] = useState(false);
    
    useEffect(() => {
        let useDate = convertDateToUTC(new Date(context.settings.year?.year1));
        useDate = useDate?.getFullYear();
        setStartYear(useDate);
    }, [context.settings.year.year1]);

    // const [ year, setYear ] = useState('cy1');
    // const [ formulary, setForm ] = useState("base");
    const [ categoryName, setCatName ] = useState();

    const [ categories, setCategories ] = useState();

    useEffect(() => {
        if (categoryName) {
            context.setSettings({
                ...context.settings,
                locked: true,
                crystal: {
                    ...context.settings.crystal,
                    category: categoryName
                },
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryName])

    const handleClick = () => {
        displayCrystalResults({
            year: context.settings.crystal.CrystalBallContractYear, 
            formulary: context.settings.crystal.CrystalBallFormulary, 
            category: categoryName, 
            loader: toggler.setLoader, 
            setCrystal: context.setCrystal, 
            casenumber: context.casenumber}, setLoading
        );
    };

    const crystalDebounce = useDebouncedCallback(autocomplete, 1000);
    useEffect(() => {
        crystalDebounce(categoryName?.toLowerCase(), setCategories, "get_catnames")
    }, [categoryName, crystalDebounce])

    const crystalInput = "border py-2 px-4 mt-2 mb-2 rounded-lg font-light w-full";
    const crystalLabel = "font-medium";
    
    return (
        <div id={id}>
            <CollapsibleButton useName={id} useValue={toggler.collapsible[id]} onClick={e => handleToggle(e, toggler)} buttonTitle={"Crystal Ball"} chevron={toggler?.collapsible[id]} />
            <div className={` transition-all duration-700 px-4 text-xs border-b border-primary-dark ${toggler.collapsible[id] ? "opacity-1 translate-y-[0] pb-3 pt-2 max-h-[330.4px]" : "opacity-0 overflow-hidden max-h-0 z-200 "}`}>
                <div>
                    <div>
                        <label className={crystalLabel} htmlFor="CrystalBallContractYear">Period:
                            <select className={crystalInput} id="CrystalBallContractYear" value={context.settings.crystal?.CrystalBallContractYear} name="CrystalBallContractYear" onChange={e => handleInputChange(e, context)}>
                                <option value="cy1">Contract Year {year1start?year1start:1}</option>
                                <option value="cy2">Contract Year {year1start?year1start + 1:2}</option>
                                <option value="cy3">Contract Year {year1start?year1start + 2:3}</option>
                                <option value="cy4">Contract Year {year1start?year1start + 3:4}</option>
                                <option value="cy5">Contract Year {year1start?year1start + 4:5}</option>
                            </select>
                        </label>
                        <label className={crystalLabel} htmlFor="CrystalBallFormulary">Formulary:
                            <select className={crystalInput} id="CrystalBallFormulary" value={context.settings.crystal?.CrystalBallFormulary} name="CrystalBallFormulary" onChange={e => handleInputChange(e, context)}>
                                <option value="standard">Standard</option>
                                <option value="target">Target</option>
                            </select>
                        </label>
                        
                        {/* <!-- Category Dropdown --> */}
                        <label className={crystalLabel} htmlFor="CrystalBallCategory">Category:
                            <SearchField name={"CrystalBallCategory"} set={categories} setName={setCatName} current={categoryName} addedStyle={crystalInput} searchId={"CrystalBallCategory"} required/>
                        </label>
                        <FormButton onClick={handleClick} buttonId={"displayButton"} buttonText={"Display"} disabled={categoryName ? false : true} loading={loading}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
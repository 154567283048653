import React, { useEffect, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import { convertList, convertToArray, deleteEvent, handleApplyEvent, loadEvents } from '../../utils/events';
import { autocomplete } from '../../utils/autoComplete';
import { handleGlobalToggle } from '../../utils/globalHandlers';
import { useDebouncedCallback  } from 'use-debounce';

export default function EventsTable({ setDrugs, target, hover, setHover, reference, editEvent, edit }) {

    const account = UseUserContext();
    const toggler = UseToggleContext();
    const context = UseContextClues();

    const [ convertedList, updateList ] = useState({});
    const [ compoundList, updateCompoundList ] = useState({});

    // this loads the initial events.. this loads into context.events with each new user login
    useEffect(() => {
        if (account.user?.userData.username) {
            loadEvents(context.addEvents);
        };
    }, [account.user?.userData.username, context.addEvents]);
    // this debounce event is for target input dropdown
    const eventsDebounce = useDebouncedCallback(autocomplete, 500);
    useEffect(() => {
        eventsDebounce(target?.toLowerCase(), setDrugs, "get_drugnames", "get_catnames")
    }, [target, eventsDebounce, setDrugs]);
    useEffect(() => {
        if (context.casenumber) {
            console.log(context.exclusionSettings.compound)
            convertList(context.exclusionSettings.events, context.events, updateList, true);
            convertList(context.exclusionSettings.compound, context.events, updateCompoundList, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.events, context.casenumber]);
    useEffect(() => { 
        convertToArray(convertedList, "events", context.setExclusionSettings);
        
    }, [convertedList, context.casenumber, context.setExclusionSettings]);
    useEffect(() => { 
        convertToArray(compoundList, "compound", context.setExclusionSettings);
    
    }, [compoundList, context.casenumber, context.setExclusionSettings]);

    // useEffect(() => {
    //     context.exclusionSettings.events.forEach(event => {
    //         updateList(data => ({
    //             ...data,
    //             [event]: true,
    //         }));
    //     });
    // }, [])
    
    return (
        <div ref={reference} style={{ height: toggler.pageHeight-430 }} className='absolute bottom-0 top-[245px] left-0 right-0 ml-0 mt-8 overflow-y-auto'>
            <table className='w-full text-center text-sm ' id="eventsTable">
                <thead>
                    <tr className='sticky top-0 bg-white shadow'>
                        <th className='text-left pl-4'>Event</th>
                        <th className="hidden">{'\t'}</th>
                        <th>Type</th>
                        <th className="hidden">{'\t'}</th>
                        <th>Target</th>
                        <th className="hidden">{'\t'}</th>
                        <th>Start Date</th>
                        <th className="hidden">{'\t'}</th>
                        <th>Modify</th>
                        <th className="hidden">{'\t'}</th>
                        <th onMouseEnter={() => handleGlobalToggle(setHover, "magnitude", false)} onMouseLeave={() => handleGlobalToggle(setHover, "magnitude", true)}>Magnitude</th>
                        <th className="hidden">{'\t'}</th>
                        <th onMouseEnter={() => handleGlobalToggle(setHover, "basis", false)} onMouseLeave={() => handleGlobalToggle(setHover, "basis", true)}>Basis</th>
                        <th className="hidden">{'\t'}</th>
                        <th>Action</th>
                        <th className="hidden">{'\t'}</th>
                        <th>Apply?</th>
                        <th className="hidden">{'\t'}</th>
                        <th className='tracking-tighter '>Compound</th>
                        <th className="hidden">{'\t'}</th>
                        <th className='tracking-tighter '>Edit</th>
                        <th className="hidden">{'\n'}</th>
                    </tr>
                </thead>
                <tbody>
                    {context?.events?.map((event, idx) => (
                        <tr key={`Index: ${idx} - events for: ${event.ename}`} className={`h-12 border bg-white overflow-y-auto ${idx%2===0?"bg-[#00141c47]":""}`}>
                            <td className='text-left pl-4'>{event.ename}</td>
                            <td className="hidden">{'\t'}</td>
                            <td>{event.etype}</td>
                            <td className="hidden">{'\t'}</td>
                            <td>{event.target}</td>
                            <td className="hidden">{'\t'}</td>
                            <td>{new Date(event.startdate).toISOString().split('T')[0]}</td>
                            <td className="hidden">{'\t'}</td>
                            <td>{event.modify}</td>
                            <td className="hidden">{'\t'}</td>
                            <td>{event.mag}</td>
                            <td className="hidden">{'\t'}</td>
                            <td>{event.basis}</td>
                            <td className="hidden">{'\t'}</td>
                            <td>
                                <button className='bg-primary-red text-white px-2 py-1 mx-2 shadow-md text-xs hover:bg-red-700 rounded' onClick={() => deleteEvent(event.id, context.addEvents)}>
                                    delete
                                </button>
                            </td>
                            <td className="hidden">{'\t'}</td>
                            <td>
                                <input 
                                    name={event.ename} 
                                    onChange={(e) => handleApplyEvent(e, updateList, convertedList, context.setSettings)} 
                                    type='checkbox'  
                                    checked={convertedList?.[event?.ename]||false} 
                                    aria-label={convertedList?.[event.ename]?`apply Event checkbox for ${event.name}`:`undo Event checkbox for ${event.name}`} 
                                />
                            </td>
                            <td className="hidden">{'\t'}</td>
                            <td className='mx-0 px-0 '>
                                <input 
                                        name={event.ename} 
                                        onChange={(e) => handleApplyEvent(e, updateCompoundList, compoundList, context.setSettings)} 
                                        type='checkbox'  
                                        checked={compoundList?.[event?.ename]||false} 
                                        aria-label={compoundList?.[event.ename]?`apply Compound on Anniversary checkbox for ${event.name}`:`undo Compound on Anniversary checkbox for ${event.name}`} 
                                    />
                            </td>
                            <td className="hidden">{'\t'}</td>
                            <td className='mx-0 px-0 '>
                                <button className={`${event.id===edit.id?"bg-primary-green rounded-full hover:bg-green-700 text-white ":"bg-primary-yellow"} text-primary-dark px-2 py-1 mx-2 shadow-md text-xs hover:bg-yellow-400 rounded`} onClick={() => (event.id===edit.id?editEvent({}):editEvent(event))}>
                                    {event.id===edit.id?"X":"edit"}
                                </button>
                            </td>
                            <td className="hidden">{'\n'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
};
import React, { useRef } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import Rates from './Rates';

export default function RatesWindow() {

    const boxref = useRef(null);
    const toggler = UseToggleContext();
    
    return (
        <>
        <div className='z-[50]' ref={boxref}>
            <div style={toggler.pageWidth<1500?{width: toggler.pageWidth}:{}} className={`fixed right-0 bottom-0 top-[100px] bg-white border py-8 pl-8 pr-8 overflow-x-auto transition-all duration-500 shadow-xl ${toggler.aux ==='rates' ? " translate-x-0 ":"translate-x-[2000px] "}`}>
            {/* <!-- Rates tab contents --> */}	
                <Rates {...{boxref}} />
            </div>
            </div>
        </>
    )
}
import React, {useState} from 'react';
import CollapsibleButton from '../Buttons/CollapsibleButton';
import { createFormulary } from '../../utils/formularySelection';
import FormButton from '../Buttons/FormButton';
import AddButton from '../Buttons/AddButtons';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { UseUserContext } from '../../context/UserContext';
import { handleInputChange } from '../../utils/claimsLoad';
import { globalBoolHandler, handleGlobalToggle, handleToggle } from '../../utils/globalHandlers';
import CustomHover from '../Hover/CustomHover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from "@fortawesome/free-solid-svg-icons";


export default function FormularySelect() {

    const id = 'formularySelect'

    const account = UseUserContext();
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [dropDown, toggleDrop] = useState(false);
    const [formularyId, setFormularyId] = useState('');
    const [baseModel, setBase] = useState('');
    const [ hover, setHover ] = useState({
        base:false,
        target:false,
    });

    const formularySelect = "border py-2 px-4 mt-2 mb-2 rounded-lg font-light";

    function handleClick(e) {
        e.preventDefault();
        createFormulary({
            formularyId,
            baseModel
        }, context.addFormularies);
    };

    function handleChange(e) {
        handleInputChange(e, context)
    };

    return (
        <div className='relative' id={id}>
            <CollapsibleButton useName={id} onClick={e => handleToggle(e, toggler)} buttonTitle={'Formulary Rate File Selection'} useValue={toggler.collapsible[id]} chevron={toggler?.collapsible[id]}/>
            <div className={` transition-all duration-700 px-4 text-xs ${toggler.collapsible[id] ? "opacity-1 translate-y-[0] pt-2 pb-3 max-h-[485px] border-b border-primary-dark " : "overflow-hidden max-h-0 z-200 "}`}>
                <div id="formSelection" className="flex flex-col">
                    <div >
                        <div className='flex flex-col font-medium'>
                            <span>Base Formulary: 
                                <FontAwesomeIcon  
                                    onMouseEnter={() => handleGlobalToggle(setHover, "base", false)} 
                                    onMouseLeave={() => handleGlobalToggle(setHover, "base", true)} 
                                    className='text-primary-blue hover:text-light-blue text-xs float-right cursor-pointer' 
                                    icon={faInfo}
                                />
                            </span>
                            <select 
                                name='baseFormulary' 
                                className={formularySelect} 
                                value={context.settings.formulary.baseFormulary} 
                                onChange={handleChange} 
                                aria-label='select a base formulary from the dropdown'
                                id='baseFormulary'
                                >
                                <option value=""></option>
                                {context.formularies.map((formulary, idx) => (
                                        <option key={idx+ " base formulary select "+ formulary} value={formulary}>{formulary}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className='flex flex-col font-medium mb-2'>
                            <span>Target Formulary: 
                                <FontAwesomeIcon  
                                    onMouseEnter={() => handleGlobalToggle(setHover, "target", false)} 
                                    onMouseLeave={() => handleGlobalToggle(setHover, "target", true)} 
                                    className='text-primary-blue hover:text-light-blue text-xs float-right cursor-pointer' 
                                    icon={faInfo}
                                />
                            </span>
                            <select 
                                name='targetFormulary' 
                                className={formularySelect} 
                                value={context.settings.formulary.targetFormulary} 
                                onChange={handleChange}
                                aria-label='select a target formulary from the dropdown' 
                                id='targetFormulary'>
                                <option value=""></option>
                                {context.formularies.map((formulary, idx) => (
                                        <option key={idx+ " target formulary select "+ formulary} value={formulary}>{formulary}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div>
                        {account.user.userData.user_type!=="uw_user"&&<AddButton handler={dropDown} buttonText={"Create New Formulary"} onClick={() => toggleDrop(!dropDown)} />}
                        <form className={`transition-all duration-700 ease-out ${dropDown ? "max-h-[300px]" : " max-h-0 overflow-hidden"}`}>
                            <FormButton buttonText={"Import new formulary"} typeB={true} addClass={"rounded-lg"} onClick={() => globalBoolHandler("importFormulary", toggler.toggle, toggler.setToggle, true)} />
                            <label className='flex flex-col my-2 gap-y-2 font-medium' htmlFor="newFormularyName">New Formulary Name:
                                <input className='py-2 px-3 border rounded-lg font-light' type="text" id="newFormularyName" placeholder="Enter new formulary name" onChange={(e) => setFormularyId(e.target.value)} />
                            </label>

                            <label className='flex flex-col my-2 gap-y-2 font-medium' htmlFor="useBaseModel">Use Base Model:
                                <select onChange={(e) => setBase(e.target.value)} className={formularySelect} id="useBaseModel">
                                    <option value=""></option>
                                    {context.formularies.map((formulary, idx) => (
                                            <option key={idx+ " create formulary select "+ formulary} value={formulary}>{formulary}</option>
                                        ))
                                    }
                                </select>
                            </label>
                            <FormButton onClick={handleClick} buttonText={"Create Formulary"} id="createNewFormularyBtn"></FormButton>
                        </form>
                    </div>
                </div>
            </div>
            <CustomHover hovered={hover.base} text={'text-xs'} top={'top-[-22px]'}alternativeBot={"absolute bottom-[-16px] right-[13px] w-3 overflow-hidden mx-auto rotate-[270deg] "} >
                This is your baseline scenario. No shifting will be applied from targeted products.
            </CustomHover>
            <CustomHover hovered={hover.target} text={'text-xs'} bottom={`${dropDown?'bottom-[404px]':'bottom-[132px]'}`} alternativeBot={"absolute bottom-[-16px] right-[13px] w-3 overflow-hidden mx-auto rotate-[270deg] "}>
                This is your “shifted” scenario. Shifting will be applied from targeted products into preferred products, based on the “Target Shift” assumption in the “Assumptions” section.
            </CustomHover>
        </div>
    )
}
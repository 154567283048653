import React, { useState } from 'react'
import { UseContextClues } from '../../context/ContextClues';
import CustomHover from '../Hover/CustomHover';
import { globalBoolHandler } from '../../utils/globalHandlers';

export default function SettingsForm({additionalClasses, handleInput, updateValue, handleAltInput, popup}) {

    const context = UseContextClues();

    const [ hover, setHover ] = useState({
        priceDate: false,
        estimateLife: false,
    });
    
    const formInput = "py-2 px-2 border my-2 rounded-lg font-light w-full";

    return (
        <>
            <div className={`mx-auto bg-white opacity-1 overflow-y-auto rounded-md ${additionalClasses}`} >
                <label htmlFor="channelDesignation"> Channel Designation:
                    <select 
                        name="channelDesignation" 
                        className={formInput} 
                        value={updateValue?updateValue.modelSettings.channelDesignation:context.settings.modelSettings.channelDesignation} 
                        onChange={e => handleAltInput?handleAltInput(e):handleInput(e, context)}
                    >
                        {context.models.modelList?.channel_mapping?.map((channel, idx) => (
                            <option 
                                key={`Channel mapping dropdown index: ${idx} - ${channel}`} 
                                value={channel}
                            >
                                {channel}
                            </option>
                        ))};
                    </select>
                </label>
                <label htmlFor='daysBreak'> Channel Days Break:
                    <div className='flex'>
                        <div className='w-full'>
                            <input 
                                name="daysBreak" 
                                type='range' 
                                min={0} 
                                max={90} 
                                className={formInput+" active:cursor-grabbing mb-0"} 
                                value={updateValue?updateValue.modelSettings.daysBreak:context.settings.modelSettings.daysBreak} 
                                onChange={e => handleAltInput?handleAltInput(e):handleInput(e, context)} 
                                list='channelDaysBreak'
                            />
                            <datalist className='flex justify-between pr-1 pl-2.5 mt-[-0.5rem]' id='channelDaysBreak' >
                                <option>0</option>
                                <option>10</option>
                                <option>20</option>
                                <option>30</option>
                                <option>40</option>
                                <option>50</option>
                                <option>60</option>
                                <option>70</option>
                                <option>80</option>
                                <option>90</option>
                            </datalist>
                        </div>
                        <div className='flex items-center justify-center border-4 ml-2 px-2 rounded-xl w-[40px] font-medium' >
                            {updateValue?updateValue.modelSettings.daysBreak:context.settings.modelSettings.daysBreak}
                        </div>
                    </div>
                </label>
                <label htmlFor="modelSpecialtyList"> Specialty List:
                    <select 
                        name="modelSpecialtyList" 
                        className={formInput} 
                        value={updateValue?updateValue.modelSettings.modelSpecialtyList:context.settings.modelSettings.modelSpecialtyList} 
                        onChange={e => handleAltInput?handleAltInput(e):handleInput(e, context)}
                    >
                        {context.models.modelList?.specialty_lists?.map((special, idx) => (
                            <option key={`Specialty list dropdown index: ${idx} - ${special}`} value={special.split(' ').join('_')}>{special}</option>
                        ))};
                    </select>
                </label>
                <label htmlFor="lDDList"> LDD List:
                    <select 
                        name="lDDList" 
                        className={formInput} 
                        value={updateValue?updateValue.modelSettings.lDDList:context.settings.modelSettings.lDDList} 
                        onChange={e => handleAltInput?handleAltInput(e):handleInput(e, context)}
                    >
                        {context.models.modelList?.ldd_lists?.map((ldd, idx) => (
                            <option 
                                key={`LDD list dropdown index: ${idx} - ${ldd}`} 
                                value={ldd.split(' ').join('_')}
                            >
                                {ldd}
                            </option>
                        ))};
                    </select>
                </label>
                <label htmlFor="dealLength" >Deal Length:
                     <div className='flex'>
                        <div className='w-full'>
                            <input 
                                name="dealLength" 
                                type='range' 
                                min={1} 
                                max={5} 
                                
                                className={formInput+" active:cursor-grabbing mb-0"} 
                                value={updateValue?updateValue.dealLength:context.exclusionSettings.dealLength} 
                                onChange={e => handleAltInput?handleAltInput(e):handleInput(e, context)} 
                                list='yearList'
                            />
                            <datalist className='flex justify-between pr-3 pl-2.5 mt-[-0.5rem]' id='yearList'>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </datalist>
                        </div>
                        <div className='flex items-center justify-center border-4 ml-2 px-2 rounded-xl w-[40px] font-medium' >
                            {updateValue?updateValue.dealLength:context.exclusionSettings.dealLength}
                        </div>
                    </div>
                </label>
                <div className='flex mt-2'>
                    <label className='mr-4 w-full' htmlFor="priceDate" onMouseEnter={() => globalBoolHandler('priceDate', hover, setHover, true)} onMouseLeave={() => globalBoolHandler('priceDate', hover, setHover, false)}> Price Date:
                        <input 
                            type='date' 
                            name="priceDate" 
                            className={formInput+" p-10"} 
                            value={updateValue?updateValue.modelSettings.priceDate:context.settings.modelSettings.priceDate} 
                            onChange={e => handleAltInput?handleAltInput(e):handleInput(e, context)} 
                        />
                    </label>
                    <label className='ml-4 w-full' htmlFor="estimateLife" onMouseEnter={() => globalBoolHandler('estimateLife', hover, setHover, true)} onMouseLeave={() => globalBoolHandler('estimateLife', hover, setHover, false)}> Estimated Life Count (Optional):
                        <input 
                            type='number' 
                            name="estimateLife" 
                            className={formInput+" p-10"} 
                            value={updateValue?updateValue.estimateLife:context.exclusionSettings.estimateLife} 
                            onChange={e => handleAltInput?handleAltInput(e):handleInput(e, context)} 
                        />
                    </label>
                </div>
            </div>
            <CustomHover hovered={hover.priceDate} bottom={popup?'bottom-[170px]':'bottom-[210px]'} alternativeBot={'absolute bottom-[-16px] left-[50px] w-3 overflow-hidden mx-auto rotate-[270deg]'}>
                Used to generate WAC pricing in the projections. Default will be today’s date if left blank.
            </CustomHover>
            <CustomHover hovered={hover.estimateLife} bottom={popup?'bottom-[170px] left-[300px]':'bottom-[210px] left-[500px]'} alternativeBot={'absolute bottom-[-16px] left-[50px] w-3 overflow-hidden mx-auto rotate-[270deg]'}>
                Can be used for PMPM estimations.
            </CustomHover>
        </>
    )
}
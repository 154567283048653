import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <div className='fixed z-[100] left-0 bottom-0 right-0 h-[30px] bg-background-light border-t-4 w-full px-2 text-[0.65em] justify-end pr-12 flex items-center'>
                <span className=''>This section can be used for links, software version, status updates, etc. ie: <Link className='hover:underline hover:text-blue-500' to={'/privacy'}>Privacy Policy</Link> </span>
            </div>
        </>
    )
}
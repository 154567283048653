import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import { UseContextClues } from '../../context/ContextClues';
import FormButton from '../Buttons/FormButton';

export default function VendorPricingExtract() {
    
    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    const [ defaultCarriers, setDefCarriers ] = useState([]);
    
    function handleChange(e) {

        // context.setSettings(data => ({
        //     ...data,
        //     locked: true
        // }))
        let list = []
        // e.forEach(ele => {
        //     ele.label!=="All"&&list.push(`${ele.value}`)
        // });
        // setList(list);
    };
    
    useEffect(() => {   
        let arr = context.exclusionSettings.carriers.map(ele => ({label: ele, value: ele}))
        setDefCarriers(arr);
    }, [context.exclusionSettings.carriers, toggler.isLoading])

    const labelStyle = 'flex flex-col pt-3 pb-1'
    return (
        <>
            <PopUpSection>
                <div className='relative w-[350px] min-h-[400px] mx-auto overflow-hidden bg-white flex items-center justify-center rounded-lg shadow-xl'>
                    <button className='absolute right-4 top-4' type='button' onClick={() => globalBoolHandler("createVendorPricingExtract", toggler.toggle, toggler.setToggle, null)}>
                        <FontAwesomeIcon className='hover:rotate-[180deg] duration-500' icon={faXmark}/>
                    </button>
                    <form className=' flex flex-col'>
                        <div className='text-lg'>tablename : <span className='font-bold'>{toggler.toggle.createVendorPricingExtract}</span></div>
                        <label className={labelStyle}> Date of service constraint
                            <input type='date' className='border py-2 px-2 rounded mt-1' />
                        </label>
                        <label className={labelStyle}> carrier constraints
                            <Select 
                                menuShouldScrollIntoView={false}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isMulti
                                options={context.models.carriers}
                                onChange={handleChange}
                                value={defaultCarriers}
                                name='carrier'
                                className='absolute mt-1 '
                            />
                        </label>
                        <FormButton buttonText={"extract"} />
                    </form>
                </div>
            </PopUpSection>
        </>
    );
};
import React, { useEffect, useState } from 'react';
import FormButton from '../Buttons/FormButton';
import SearchField from '../SearchField/SearchField';
import { globalChangeHandler } from '../../utils/globalHandlers';
import { UseContextClues } from '../../context/ContextClues';
import { addEvent, editSingleEvent } from '../../utils/events';



export default function EventForm({drugs, setDrugs, target, setTarget, edit, editEvent}){

    const context = UseContextClues();

    const [ fields, setFields ] = useState({
        basis: "absolute",
        etype: "drug",
        ename: "",
        mag: 0,
        startdate: "",
        modify: "rebaterate"
    });
    const [locked, setLocked] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        addEvent({
            ...fields,
            target,
        }, context.events, context.addEvents);
    };

    function handleEdit(e) {
        e.preventDefault();
        editSingleEvent(fields, edit.id, context.setSettings, context.events, context.addEvents, editEvent);
    }

    useEffect(() => {
        if (fields.modify === "wac" || fields.modify === "volume" ) {
            setFields(content => ({
                ...content,
                basis: "relative"
            }));
            setLocked(true)
        } else {
            setFields(content => ({
                ...content,
                basis: "absolute"
            }));
            setLocked(false)
        }
    }, [fields.modify])

    useEffect(() => {
        if (edit.id) {
            setFields({
                basis: edit.basis,
                ename: edit.ename,
                etype: edit.etype,
                modify: edit.modify,
                startdate: edit.startdate,
                mag: edit.mag,
                target: edit.target,
                id: edit.id
            }); 
        } else {
            setFields({
                basis: "absolute",
                etype: "drug",
                ename: "",
                mag: 0,
                target: "",
                startdate: "",
                modify: "rebaterate"
            });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit.id]);

    const labelStyle = "w-full text-sm flex flex-col";
    const inputStyle = "py-1 my-1 px-3 w-full text-xs border rounded";

    return (
        <>
            <form className='h-full ' id="eventForm" onSubmit={edit.id?handleEdit:handleSubmit}>
                <div className='flex gap-2'>
                    <label className={labelStyle} htmlFor="eventName">Name:
                        <input className={inputStyle} type="text" id = "eventName" name = "ename" onChange={e => globalChangeHandler(e, fields, setFields)} value={fields.ename} required/>
                    </label>
                    <label className={labelStyle} htmlFor="etype">Type:
                        <select className={inputStyle} id="etype" name="etype"  onChange={e => globalChangeHandler(e, fields, setFields)} value={fields.etype}>
                            <option value="drug">Drug Name</option>
                            <option value="category">Category (GPI 6)</option>
                            <option value="gpi2">GPI 2</option>
                            <option value="mnoy">MNOY</option>
                        </select>
                    </label>
                </div>
                <div className='flex gap-2'>
                    <label className={labelStyle} htmlFor="target">Target(s):
                        <SearchField set={drugs}  searchId={"target"} addedStyle={inputStyle} current={edit.id?fields.target:target} setName={setTarget} />
                    </label>
                    <label className={labelStyle} htmlFor="startdate">Start Date:
                        <input className={inputStyle} type="date" id="startdate" name="startdate" onChange={e => globalChangeHandler(e, fields, setFields)} value={edit.id?fields?.startdate?.substring(0,10):fields.startdate} required />
                    </label>
                </div>
                <div className='flex gap-2'>
                    <label className={labelStyle} htmlFor="modify">Modify:
                        <select className={inputStyle} id="modify" name="modify" onChange={e => globalChangeHandler(e, fields, setFields)} value={fields.modify} required >
                            <option value="rebaterate">Rebate Rate</option>
                            <option value="volume">Rx Volume</option>
                            <option value="wac">WAC</option>
                        </select>
                    </label>
                    <label className={labelStyle} htmlFor="mag">Magnitude:
                        <input className={inputStyle} type="number" id="mag" name="mag" value={fields.mag} onChange={e => globalChangeHandler(e, fields, setFields)} required />
                    </label>
                    <label className={labelStyle} htmlFor="basis">Basis:
                        <select className={inputStyle} id="basis" name="basis" value={fields.basis} onChange={e => globalChangeHandler(e, fields, setFields)} required disabled={locked} >
                            <option value="absolute">Absolute</option>
                            <option value="relative">Relative</option>
                        </select>
                    </label>
                </div>
                <div className={`${edit.id?"flex gap-2":""}`}>
                    <FormButton type="submit" buttonId="addEvent" buttonText={edit.id?"Edit Event":"Add Event"} />
                    {edit.id&&<FormButton type="button" buttonId="addEvent" buttonText={"cancel"} onClick={() => editEvent({})}  />}
                </div>
            </form>
        </>
    )
};
import React, { useEffect, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faXmark } from "@fortawesome/free-solid-svg-icons";
import { globalBoolHandler } from '../../utils/globalHandlers';
import PopUpSection from '../Section/PopUpSection';
import { formatBytes, importFormularyRate } from '../../utils/formularySelection';
import FormButton from '../Buttons/FormButton';
import CustomHover from '../Hover/CustomHover';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleInfo, faInfo } from "@fortawesome/free-solid-svg-icons";

export default function ImportFormulary() {

    const toggler = UseToggleContext();
    const context = UseContextClues();

    const [ newName, setNewName ] = useState("");
    const [ errorMessage, setError ] = useState("");
    const [ file, setFile ] = useState();
    const [ loading,  setLoading ] = useState();
    const [ hover, setHover ] = useState(false);
    
    function handleFileLoad(e) {
        const uploadedFile = e.target.files[0]
        if (uploadedFile?.size >= 10000000) {
            alert("File size cannot be over 100MB")
        } else {
            setFile(uploadedFile);
        }
    };

    function handleSubmit(e){
        e.preventDefault();
        setLoading(true);
        importFormularyRate(newName.split(" ").join("_"), file, setLoading, setError, globalBoolHandler, toggler, context);
    };

    window.addEventListener("dragover",function(e){
        e.preventDefault();   
    },false);
    window.addEventListener("drop",function(e){
        e.preventDefault();  
    },false);

    function handleFileDrop(e) {
        e.stopPropagation();
        e.preventDefault();

        const {files} = e?.dataTransfer;
        
        const uploadedFile = files[0]
        if (uploadedFile?.size >= 10000000) {
            alert("File size cannot be over 100MB")
        } else {
            setFile(uploadedFile);
        };
    };

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                globalBoolHandler("importFormulary", toggler.toggle, toggler.setToggle, false)
            }
        };
        window.addEventListener('keydown', handleEsc);
     
        return () => {
           window.removeEventListener('keydown', handleEsc);
        };
        
    }, [toggler.toggle, toggler.setToggle]);

    function handleCancel(e) {
        e.preventDefault();
        setFile(null);
    };

    return (
 
        <PopUpSection>
            <form className={`relative max-w-4xl mx-auto justify-center`} onSubmit={handleSubmit}>
                <button type='button' className='absolute right-[15px] top-[12px] hover:rotate-[180deg]' onClick={() => globalBoolHandler("importFormulary", toggler.toggle, toggler.setToggle, false)}>
                    <FontAwesomeIcon icon={faXmark} className='hover:rotate-[180deg] duration-500'/>
                </button>
                <div className=' grid grid-cols-[1fr,1fr] gap-4 mx-auto bg-white opacity-1 overflow-y-auto py-8 px-6 rounded-md' >
                    <div className='h-full flex flex-col justify-between'>
                        <div className='text-xl font-bold'>Import new formulary</div>
                        <div className='text-xs '>Load a set of formularies on the right and type in a new formulary name.</div>
                        <div className='text-xs '>
                            The .csv must follow this format:
                            <br />
                            <span className='font-bold'>First column:</span> "drugname" or "ndc" in the header. 
                            <br />
                            <span className='pl-4'>Does not have to include leading 0's.</span>
                            <br />
                            <span className='font-bold'>Second column:</span> "rate" in the header. 
                            <br />
                            <span className='pl-4'>Can be in percentage or decimal format. </span>
                            <br />
                            <span className='font-bold'>Third column:</span> <span >"tpn"
                                <FontAwesomeIcon  
                                    onMouseEnter={() => setHover(true)} 
                                    onMouseLeave={() => setHover(false)} 
                                    className='text-primary-blue hover:text-light-blue text-xs' 
                                    icon={faCircleInfo}
                                /></span> in the header. 
                            <br />
                            <span className='pl-4'>Valid values are “T”,”P”,”N”. </span>
                        </div>
                        <div className='text-xs'>
                            Once complete, select <span className='font-bold'>load</span>.
                        </div>
                        <label className='text-sm' htmlFor='formularyName'>
                            Formulary Name:
                            <input name='formularyName' type='text' onChange={e => setNewName(e.target.value)} className='w-full border px-2 py-1 rounded-lg' />
                        </label>
                        {errorMessage&&<div className='text-primary-red text-xs'>Error: {errorMessage}</div>}
                        <FormButton buttonText={"Load"} loading={loading} loadingSize={15} />
                    </div>

                    <label 
                        htmlFor="dropzone-file" 
                        className="flex flex-col items-center justify-center w-full h-[350px] border-2 border-gray-300 border-dashed rounded-md cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                        onDrop={handleFileDrop} onDrag={handleFileDrop} onDragEnter={handleFileDrop} onDragLeave={handleFileDrop} 
                        >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6 ">
                            {!file ?
                                <>
                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    <p className="mb-1 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs mb-1 text-gray-500 dark:text-gray-400">.csv files only</p>
                                    <p className="text-xs text-gray-500 dark:text-primary-red">Maximum file size 100 MB</p>
                                </>:<>
                                    <div className='text-center w-[300px] text-sm'>
                                        <span className='pr-2'>
                                            {file.name}
                                        </span>
                                        <span className='pr-8'>
                                            ({formatBytes(file.size)})
                                        </span>
                                        <button type='button' onClick={handleCancel}>
                                            <FontAwesomeIcon className=' hover:text-primary-light' icon={faXmark} />
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                        <input name='csvfile' id="dropzone-file" className='hidden' type='file' accept='.csv' onChange={handleFileLoad}  onClick={e => e.target.value = null} />
                    </label> 
                    <CustomHover hovered={hover} text={'text-xs'} bottom={'bottom-[240px]'} left={'left-[30px]'} alternativeBot={'absolute bottom-[-16px] left-[107px] w-3 overflow-hidden mx-auto rotate-[270deg]'} >
                        <div>
                            "T" - Targeted product, shift out
                            <br />
                            "P" - Preferred product, shift in on weighted basis
                            <br />
                            "N" - Neutral, no shift
                        </div>
                    </CustomHover>
                </div>
            </form>
        </PopUpSection>
    )
};
import React, {  useState  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function CustomSelect({data, type, fileSelector, selectFile}) {

    const [ dropped, toggleDrop ]= useState(false);

    function selectChoice(e, value) {
        selectFile(data => ({
            ...data,
            [type]: value
        }));
        toggleDrop(false)
    };

    // const handleClick = HandleOutsideClick(boxRef, togglefocxu)
    // const focused = HandleOutsideClick(boxRef, toggleDrop)

    return (
        <div className='' >
            <div className='border px-4 rounded-lg py-1 w-[320px] flex justify-between' onClick={() => toggleDrop(!dropped)}>
                <span className='pr-4'>{fileSelector[type]?fileSelector[type]:" --- Choose one --- "}</span>
                <FontAwesomeIcon className={`float-right`} icon={faChevronDown}/> 
            </div>
            {dropped&&<ul className='fixed z-[400] border max-h-[400px] bg-white overflow-y-auto min-h-[150px] w-[320px] rounded-b-lg shadow-xl'>
                {data&&data.map((content, idx) => (
                    <li key={`${idx}: custom selector ${content.table_name}`} onClick={(e) => selectChoice(e, content.table_name)} className='py-2 border hover:bg-primary-dark hover:text-white px-2'>
                        <div className='flex justify-between'>
                            <span className='font-bold pr-10'>{content.table_name}</span>
                            <span>{content.created_by}</span>
                        </div>
                        <span className='text-xs flex'>{content.created_at.substring(0,10)}</span>
                    </li>
                ))}
            </ul>}
        </div>
    )
};
import React, { useEffect, useRef, useState } from 'react';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { downloadToPDFGlobal } from '../../utils/download';
import Summary from './Summary';
import PricingLensControls from '../Tables/PricingLensControls';
import PricingLensNumbers from '../Tables/PricingLensNumbers';
import PricingTable from '../Tables/PricingTable';
import { rowTotal } from '../../utils/pricingLens';
import PerformanceLens from './PerformanceLens';
import LockedMessage from '../Error/LockedMessage';


export default function PricingLens({switchContext}) {

    const context = UseContextClues();
    const toggler = UseToggleContext();
    
    const labelStyle = "font-bold text-xs";

    const panelRef = useRef(null);

    const [ message, setMessage ] = useState("");

    useEffect(() => {
        rowTotal(switchContext, context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchContext, context.results,context.pricingLens[switchContext]?.priceLens,context.pricingLens[switchContext]?.priceLens?.price, context.pricingLens[switchContext]?.hedgeCheck, context.pricingLens[switchContext]?.priceLens?.blanket, context.pricingLens[switchContext]?.priceLens?.passBack, context.pricingLens[switchContext]?.passBackbyYear, context.pricingLens[switchContext]?.percentageByYear ]);

    useEffect(() => {
        if (context.pricingLens[switchContext]?.priceLens?.blanket < 0&&!context.pricingLens[switchContext]?.hedgeCheck) {
            alertUserOnNegativeValue(true);
        } else {
            alertUserOnNegativeValue(false);
        };
        if (switchContext!=="summary"&&switchContext!=="performance") {
            Object.keys(context.pricingLens[switchContext]?.priceLens?.price).map(year => 
                Object.entries(context.pricingLens[switchContext]?.priceLens?.price[year]).forEach(channel => {
                    if (channel[1]<0&&context.pricingLens[switchContext]?.hedgeCheck) {
                        alertUserOnNegativeValue(true);
                    }
                })
            );
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.pricingLens[switchContext]?.priceLens?.blanket, context.pricingLens[switchContext]?.priceLens?.price, context.pricingLens[switchContext]?.hedgeCheck])

    function alertUserOnNegativeValue(bool) {
        if (bool) {
            setMessage("You are entering a negative hedge - this will cause your values to increase!");
        } else {
            setMessage("");
        };
    };

    return (
        <>
            {switchContext!=="summary"&&switchContext!=="performance"&&<div>
                <div className='flex justify-between p-3 text-sm border gap-20 rounded-lg bg-background-light'>
                    <PricingLensControls {...{switchContext }} />
                    <PricingLensNumbers {...{switchContext }} />
                </div>
            </div>}

            {/* this is the changiong title */}

            <div id="standardCharts" className=''>
                <div className='text-lg w-full font-medium my-4 flex justify-between'>
                    {switchContext!=="performance"&&(switchContext==="summary"?`Summary for Standard (${context.settings.formulary.baseFormulary}) and Target (${context.settings.formulary.targetFormulary}) Scenarios`:<>{switchContext==="results"?"Standard":"Target"} Formulary Projections {switchContext==="results"?`(${context.settings.formulary.baseFormulary})`:`(${context.settings.formulary.targetFormulary})`}</>)}
                    {switchContext==="performance"&&(<div className='w-full text-sm text-left py-4 border-2  p-2 bg-background-light rounded'>
                        Input contractual guarantees to examine performance.  
                        <br />
                        Please ensure contractual exclusions are reflected in the contractual definitions section. 
                        <br />
                        Once confirmed, make sure Results tab has been re-run.
                    </div>)}
                    <span className='text-sm text-primary-red flex items-center'>
                        {message.length>0&&<FontAwesomeIcon className='text-sm mr-4' icon={faTriangleExclamation}/>}
                        {message}
                    </span>
                    <button aria-label='download to pdf' className='pl-10' >
                        <FontAwesomeIcon className={'text-2xl text-light-blue hover:text-hover-blue'} icon={faFilePdf} onClick={() => downloadToPDFGlobal(panelRef, switchContext, true, switchContext==="summary"?"landscape":"portrait", {avoid: "tr"}, null, null, toggler.setStatus )  }/>
                    </button>
                </div>
            </div>

            {/* this is the dynamic table */}

            <div  className={` ${context[switchContext] ? "opacity-1": "opacity-0"}`}>
                {/* this is the table for standard and target */}
                {context[switchContext]&&(switchContext==="target"||switchContext==="results")&&<div ref={panelRef} >
                    {Object.keys(context[switchContext]).map((section, idx) => (
                        section!=="Brand_WAC_Summary"&&<div key={`Results data: ${section}, index: ${idx}`}>
                            <div className={labelStyle}>{section.split("_").join(" ")} <LockedMessage /></div>
                            <PricingTable {...{switchContext, section}} />
                        </div>
                    ))}
                </div>}
            </div>   
            {switchContext==="summary"&&
                <Summary {...{ reference:panelRef }}/>
            }
     
            {switchContext==="performance"&&
                <PerformanceLens {...{ reference:panelRef }}/>
            }
            <div className='mt-20 text-sm font-medium'>
                Results have been annualized with a factor of {context.mainData?.annualization?context.mainData?.annualization.toLocaleString('en-US', {maximumFractionDigits: 3}):"N/A"}*
            </div>
        </>
    );
};
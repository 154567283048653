import { useEffect, useState } from 'react';

export default function HandleOutsideClick(box, setSelected){

    const [isClicked, setIsClicked] = useState();

    useEffect(() => {
        function handlClickOutside(e){
            if (box.current && !box.current.contains(e.target)) {
                setIsClicked(true);
            } else setIsClicked(false);
            setSelected('');
        };
        document.addEventListener("mousedown", handlClickOutside);
        return () => {
            document.addEventListener("mousedown", handlClickOutside);
        }
    }, [box,setSelected])
    return isClicked
};
import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { getContractGuarantees, getVendorDeals } from '../../utils/vendorConsole';
import { UseContextClues } from '../../context/ContextClues';
import { formatDateOnly } from '../../utils/globalHandlers';
import Select from 'react-select'
import { UseToggleContext } from '../../context/ToggleContexts';

export default function ClientPerformanceScenario({toggle, scenario, clientPerfData, setClientPerfData, setLoading}) {
    
    const context = UseContextClues();
    const toggler = UseToggleContext();

    const labelStyles = "flex flex-col pb-2";

    const [ vendors, set ] = useState();

    useEffect(() => {
        getVendorDeals(set)
    }, []);

    const [ inputs, changeInput ] = useState({
        vendor_name: "",
        formulary_name: "",
        lob_name: ""
    });

    function runPerformance(e) {
        e.preventDefault();
        getContractGuarantees(setClientPerfData, scenario, context.casenumber, formatDateOnly(context.settings.year.year1), inputs.vendor_name, inputs.formulary_name, inputs.lob_name, setLoading, toggler.setStatus)
        if (clientPerfData) {
            toggle(false);
        };
    };

    function handleChange(value, vendor) {
        if (value) {
            changeInput(data => ({
                ...data,
                [vendor]: value.value
            }));
        } else {
            changeInput(data => ({
                ...data,
                [vendor]: ''
            }));
        };
    };

    const [ formularies, setForms ] = useState([]);

    useEffect(() => {
        let temp = [];
        if (vendors?.[inputs.vendor_name]) {
            vendors[inputs.vendor_name].forEach(ele => {
                if (!temp.includes(ele.formulary_name)) {
                    temp.push(ele.formulary_name)
                }
            });   
        };
        setForms(temp)
    }, [vendors, inputs.vendor_name]);

    return (
        <>
            <PopUpSection popone={"absolute top-0 z-[200] h-[100vh] w-[100vw] bg-primary-dark opacity-[0.6] ml-[-33px]"}>
                <div className='relative w-[500px] h-[400px] mx-auto bg-white border shadow-2xl rounded-lg'>
                    <form className=' p-8' onSubmit={runPerformance}>
                        {vendors?<label className={labelStyles}>vendor name
                            <Select required onChange={val => handleChange(val, "vendor_name")} options={Object.keys(vendors).map(ele => ({value: ele, label: ele}))} />
                        </label>:null}
                        {inputs.vendor_name?<label className={labelStyles}>formulary name
                            <Select required onChange={val => handleChange(val, "formulary_name")} options={formularies.map(ele => ({value: ele, label: ele}))} />
                        </label>:null}
                        {inputs.formulary_name?<label className={labelStyles}>lob name
                            <Select required onChange={val => handleChange(val, "lob_name")} options={vendors[inputs.vendor_name].filter(ele => ele.formulary_name===inputs.formulary_name).map(ele => ({value: ele.lob_name, label: ele.lob_name}))} />
                        </label>:null}
                        <button type='submit' className='absolute bottom-8 py-2 w-[440px] rounded-full border text-white bg-light-blue hover:bg-hover-blue hover:shadow-xl'>Run</button>
                    </form>
                    <button className='absolute right-[15px] top-[12px]' onClick={() => toggle(false)} >
                        <FontAwesomeIcon className='hover:rotate-[180deg] duration-500' icon={faXmark}/>
                    </button>
                </div>
            </PopUpSection>
        </>
    );
};
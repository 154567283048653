import React, { useEffect, useMemo, useRef, useState } from 'react';
import FormButton from '../Buttons/FormButton';
import { addDrugRate, exportRateFileDownload, loadRateChart, saveChanges } from '../../utils/rates';
import { autocomplete } from '../../utils/autoComplete';
import EditInput from '../EditInput/EditInput';
import EditSelect from '../EditInput/EditSelect';
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import HoverTPN from '../Hover/HoverTPN';
import SearchField from '../SearchField/SearchField';
import { useDebouncedCallback  } from 'use-debounce';

export default function Rates({height}){

    const rateChart = useRef(null);
    const categoryDropDown = useRef(null);
    const drugDropDown = useRef(null);

    const context = UseContextClues();
    const toggler = UseToggleContext();

    const [ ratesData, setRatesData ] = useState([]);
    const [ resetRatesRef, setResetRatesRef ] = useState([]);
    const [ resetTPNRef, setResetTPNRef ] = useState([]);
    
    const [pickedDrug, pickDrug] = useState("all");
    const [pickedCategory, pickCategory] = useState("all");

    const [filtered, setFiltered] = useState([])
    const [ drugDrop, setDrugDrop ] = useState([]);
    const [ filteredDrugDrop, setFilteredDrugDrop ] = useState([]);
    const [ categoryDrop, setCatDrop ] = useState([]);
    const [ filteredCategoryDrop, setFilteredCatDrop ] = useState([]);

    useEffect(() => {
        setFiltered(ratesData)
    }, [ratesData]);

    function filterAlphebetically(type) {
        let alphabetical = [...filtered].sort((a, b) => a[type].localeCompare(b[type]));
        setFiltered(alphabetical);
    };

    useMemo(() => {
        function filterResultsFurther(word, category){
            let filteredArr = []
            let filteredDrugs = []
            let filteredCategories = []
    
            if (word === "all" && category === "all") {
                setFiltered([
                    ...ratesData
                ]);
                setFilteredCatDrop([...categoryDrop]);
                setFilteredDrugDrop([...drugDrop]);
            } else if ((word === "all"|| !word) && category) {
                for (let i = 0; i < ratesData?.length; i++) {
                    if (ratesData[i].category?.includes(category)) {
                        filteredArr.push(
                            ratesData[i]
                        );
                        if (!filteredDrugs.includes(ratesData[i]?.drugname)) {
                            filteredDrugs.push(
                                ratesData[i]?.drugname
                            );
                        }
                    };
                };
                setFiltered(filteredArr);
                setFilteredDrugDrop([...filteredDrugs]);
            } else if (word && (category === "all"|| !category)) {
                for (let i = 0; i < ratesData?.length; i++) {
                    if (ratesData[i].drugname?.includes(word)) {
                        filteredArr.push(
                            ratesData[i]
                        );
                        if (!filteredCategories.includes(ratesData[i]?.category)) {
                            filteredCategories.push(
                                ratesData[i]?.category
                            );
                        }
                    };
                };
                setFiltered(filteredArr);
                setFilteredCatDrop([...filteredCategories]);
            } else if (category) {
                for (let i = 0; i < ratesData?.length; i++) {
                    if (ratesData[i].drugname?.includes(word) && ratesData[i].category?.includes(category)) {
                        filteredArr.push(
                            ratesData[i]
                        );
                        filteredDrugs.push(
                            ratesData[i]?.drugname
                        );
                        filteredCategories.push(
                            ratesData[i]?.category
                        );
                    }
                };
                setFiltered(filteredArr);
                setFilteredDrugDrop([...filteredDrugs]);
                setFilteredCatDrop([...filteredCategories]);
            };
        };
        filterResultsFurther(pickedDrug, pickedCategory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickedDrug, pickedCategory])


    function filterResultsNow(e){
         if (e.target.id === "drugDropdown") {
            // filterCategoryDropdownBasedOnDrug(e.target.value, categoryDropDown.current, rateChart);
            pickDrug(e.target.value)
        } else {
            pickCategory(e.target.value)
            // filterDrugDropdownBasedOnCategory(e.target.value, drugDropDown.current, rateChart);
        };
    };

    const [ addDrop, toggleAdd ] = useState(false);
    
    const [ drugName, setDrugName ] = useState('');
    const [ drugs, setDrugs ] = useState(null);

    const [ categoryName, setCatName ] = useState('');
    const [ categories, setCategories ] = useState(null);

    const [ mfr, setMfr ] = useState();
    const [ mfrList, setMfrList ] = useState([]);
    const [ rate, setRate ] = useState(null);
    const [ tpn, setTpn ] = useState();
    const [ formulary, setFormulary ]  = useState("");

    const [ hovered, setHovered ] = useState({
        top: false,
        bottom: false
    });
    const [ downloadLoad, resetDownload ] = useState(false);

    const drugDebounce = useDebouncedCallback(autocomplete, 1000);
    useEffect(() => {
        drugDebounce(drugName?.toLowerCase(), setDrugs, "get_drugnames")
    }, [drugName, drugDebounce]);
    const catDebounce = useDebouncedCallback(autocomplete, 1000);
    useEffect(() => {
        catDebounce(categoryName?.toLowerCase(), setCategories, "get_catnames")
    }, [categoryName, catDebounce]);
    const mfrDebounce = useDebouncedCallback(autocomplete, 1000);
    useEffect(() => {
        mfrDebounce(mfr?.toLowerCase(), setMfrList, "get_mfrs")
    }, [mfr, mfrDebounce])
    useEffect(()=> {
        let drugs = new Set();
        let categories = new Set();
        ratesData?.forEach(el => {
            drugs.add(el.drugname)
            categories.add(el.category)
        })
        setDrugDrop(drugs);
        setFilteredDrugDrop(drugs)
        setCatDrop(categories);
        setFilteredCatDrop(categories)
    }, [ratesData]);

    useEffect(() => {
        pickCategory("all")
        pickDrug("all")
    }, [formulary]);

    useEffect(() => {
        setResetRatesRef(ratesData?.map(e => React.createRef()))
        setResetTPNRef(ratesData?.map(e => React.createRef()))
    }, [ratesData])

    function toggleDropdown(e){
        e.stopPropagation();
        toggleAdd(!addDrop);
    };

    function handleSubmit(e){
        e.preventDefault();
        e.stopPropagation();
        addDrugRate(drugName, `${rate/100}`, tpn, categoryName, mfr, formulary, toggler.setStatus, ratesData, setRatesData);
    };

    function resetRates(e) {
        e.stopPropagation();
        if (window.confirm("Are you sure you want to discard all changes?")) {
            resetRatesRef?.map(el => el.current.resetRates())
            resetTPNRef?.map(el => el.current.resetTPN())
        }
    };

    function handleHover(name, bool){
        setHovered({
            ...hovered,
            [name]: bool
        });
    };

    function handleSaveChanges(e){
        e.stopPropagation();
        saveChanges(formulary, rateChart, toggler.setStatus, setRatesData);
        setTimeout(() => {
            pickCategory("all");
            pickDrug("all");
        },1500);
    };

    function handleFormSelect(e) {
        loadRateChart(e.target.value, setRatesData);
        setFormulary(e.target.value);
    };

    const labelStyle = "relative flex  flex-col text-sm font-medium w-full text-left ";
    const inputStyle = "w-full border mb-1 mt-1 px-3 py-2 font-light rounded";
    const formTable = " pr-4 py-2"

    return (
 
                <div id="rates" className=" h- tabcontent overflow-y-auto pr-8">
                    <div>
                        <div className='text-lg font-bold '>Rates For Formulary</div>
                    </div>

                    <div className="submission_open flex flex-col">
                        <label className="pt-3 pb-2 font-medium text-sm">
                            Select Form ID
                            <select onClick={e => e.stopPropagation()} value={formulary} className={inputStyle+" absoute"} id="formulary" onChange={handleFormSelect} >
                                <option value=""></option>
                                {context.formularies.map((formulary, idx) => (
                                        <option key={idx+ " rates formulary select "+ formulary} value={formulary}>{formulary}</option>
                                    ))
                                }
                            </select>
                        </label>
                    </div>
                    {/* Gross Rates inclusive of all manufacturer remuneration */}
                    {formulary&&<div className='flex gap-x-10'>
                        <FormButton onClick={toggleDropdown} buttonText={"Add new rate"} />
                        <FormButton onClick={() => exportRateFileDownload(formulary, resetDownload)} buttonText={"Export rates file"} loading={downloadLoad} />
                        <FormButton onClick={handleSaveChanges} buttonText={"Save Changes"} />
                        <FormButton onClick={resetRates} buttonText={"Discard Changes"} />     
                    </div>}
                    <br/>
                    <HoverTPN  hovered={hovered.bottom} top="top-[105px]" right={"right-[90px]"} />
                    <div className='overflow-y-auto border-2 rounded-lg '>
                        <div style={{height: toggler.pageHeight-(height?height:365) }} className='transition-all text-left duration-1000 text-sm' id="table-wrapper">												
                            {formulary&&
                                <form onSubmit={handleSubmit}>
                                    <table  ref={rateChart} className='w-full' id="rateChart">
                                        <thead className={formulary ? "opacity-1" : "opacity-0"}>
                                            <tr className='sticky top-0 bg-white left-2 z-[200] border-b shadow'>
                                                <th className="py-2 pl-4 pr-2 whitespace-nowrap min-w-[400px]">
                                                    <span className='cursor-pointer' onClick={() => filterAlphebetically('drugname')}>Drug Name</span>
                                                    <select ref={drugDropDown} id="drugDropdown" className='border ml-4 rounded font-light' onChange={filterResultsNow} value={pickedDrug}>
                                                        <option value="all">All</option>
                                                        {Array.from(filteredDrugDrop)?.map((drug, idx) => (
                                                            <option key={`Rates dropdown drug: ${drug}, index: ${idx}`}>
                                                                {drug}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </th>
                                                <th className="py-2 pr-2 whitespace-nowrap min-w-[500px] flex items-center">
                                                    <span className='cursor-pointer' onClick={() => filterAlphebetically('category')}>Category</span>
                                                    <select ref={categoryDropDown} id="categoryDropDown" className='border ml-4 rounded font-light' onChange={filterResultsNow} value={pickedCategory}>
                                                        <option value="all">All</option>
                                                        {Array.from(filteredCategoryDrop)?.map((category, idx) => (
                                                            <option key={`Rates dropdown category: ${category}, index: ${idx}`}>
                                                                {category}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </th>
                                                <th className="py-2"><span className='cursor-pointer' onClick={() => filterAlphebetically('mfr')}>Manufacturer</span></th>
                                                <th className="py-2">Rate</th>
                                                <th className="w-20 text-center py-2 " onMouseEnter={() => handleHover("bottom",true)} onMouseLeave={() => handleHover("bottom",false)} >TPN</th>
                                                <th className="py-2 px-6 whitespace-nowrap">Edit Rate</th>
                                                <th className="py-2 pr-2 text-right whitespace-nowrap">Edit TPN</th>
                                            </tr>
                                        </thead>
                                        <tbody className={`overflow-y-auto text-left p-1 transition-all z-[200] ${!addDrop?"translate-y-[-56px]":"translate-y-0"}`}>
                                            {/* this is our form */}
                                            <tr className={`bg-primary-dark sticky top-9 transition-all duration-500 ${!addDrop?"translate-y-[-100px]":"translate-y-0"}`}>
                                                <td className={formTable+`  pl-4 ${!addDrop?"translate-y-[-100px]":"translate-y-0"}` }>
                                                    <label className={labelStyle} htmlFor="drugName">
                                                        <SearchField  
                                                            set={drugs} 
                                                            setName={setDrugName} 
                                                            current={drugName} 
                                                            addedStyle={inputStyle} 
                                                            placeholder={"Enter drug name"} 
                                                            searchId={"addRateDrugName"}
                                                        />
                                                    </label>
                                                </td>
                                                <td className={formTable}>
                                                    <label className={labelStyle} htmlFor="drugName">
                                                        <SearchField 
                                                            set={categories} 
                                                            setName={setCatName} 
                                                            current={categoryName} 
                                                            addedStyle={inputStyle} 
                                                            placeholder={"Enter category"} 
                                                            searchId={"addRateCategory"}
                                                        />
                                                    </label>
                                                </td>
                                                <td className={formTable}>
                                                    <label className={labelStyle} htmlFor="drugName">
                                                        <SearchField 
                                                            set={mfrList} 
                                                            setName={setMfr} 
                                                            current={mfr} 
                                                            addedStyle={inputStyle} 
                                                            placeholder={"Enter manufacturer"} 
                                                            searchId={"addMfrList"}
                                                        />
                                                    </label>
                                                </td>
                                                <td className={formTable}>
                                                    <label className={labelStyle} htmlFor="rate">
                                                        <div className='flex items-center'>
                                                            <input 
                                                                className={inputStyle+" w-[100px]"} 
                                                                type="number" 
                                                                id="addRateRate" 
                                                                name="addRateRate" 
                                                                placeholder="Enter rate (%)" 
                                                                step={0.001} 
                                                                min={0} 
                                                                max={100} 
                                                                onChange={e => setRate(e.target.value)} required
                                                            />
                                                            <span className='pl-1 text-white'>%</span>
                                                        </div>
                                                    </label>
                                                </td>
                                                <td className={""}>
                                                    <label className={labelStyle} htmlFor="TPN" onMouseEnter={() => handleHover("top",true)} onMouseLeave={() => handleHover("top",false)} >
                                                        <select className={inputStyle+' min-w-[60px]'} id='addRateTpn' name='addRateTPN' onChange={e => setTpn(e.target.value)} required>
                                                            <option value={""}> -- select an option -- </option>
                                                            <option value={"T"}>T</option>
                                                            <option value={"P"}>P</option>
                                                            <option value={"N"}>N</option>
                                                        </select>
                                                    </label>
                                                </td>
                                                <td colSpan={2} className={formTable+ " pl-4 text-center " }>
                                                    <FormButton buttonId={"addDrugButton"} buttonText={"Add Drug"} addClass={"w-[110px] mt-auto "} />
                                                </td>
                                            </tr>
                                            {filtered?.map((el, idx) => (
                                                <tr className={`border h-14 ${!addDrop&&"last:border-none"} ${idx%2===0?"bg-[#00141c47]":""}`} key={`Rates data drug: ${el.drugName}, index: ${idx}`}>
                                                    <td className='pl-4'>{el.drugname}</td>
                                                    <td>{el.category}</td>
                                                    <td className='pr-4'>{el.mfr}</td>
                                                    <td className='pl-2'>{(el.rate*100).toFixed(3)}%</td>
                                                    <td className='text-center'>{el.tpn}</td>
                                                    <td className='pl-6'>
                                                        <EditInput 
                                                            defaultValue={(el.rate*100).toFixed(3)}
                                                            min={0}
                                                            max={100}
                                                            step={0.001}
                                                            resetRef={resetRatesRef?.[idx]}
                                                        />
                                                    </td>
                                                    <td className='flex items-center flex-col  h-12 justify-center '>
                                                        <EditSelect defaultValue={el.tpn} tpnRef={resetTPNRef?.[idx]}/>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </form>
                            }
                        </div>
                    </div>
                </div>

    )
};
import React, { useState } from 'react';
import { UseToggleContext } from '../../context/ToggleContexts';
import PricingLens from './PricingLens'

export default function PricingLensPanel() {
    const toggler = UseToggleContext();
    const [ type, toggleType ] = useState('results');

    const hoverStyle = "hover:border-b-4 hover:mb-[-3px] hover:border-b-light-blue"

    return (
        <>
            <div className={`fixed z-[50] right-0 bottom-[30px] top-[100px] bg-white border py-4 pl-8 pr-10 transition-all duration-500 shadow-xl  ${toggler.aux ==='pricingLens' ? " translate-x-0 ":"translate-x-[2000px] "}`}>
                <div className='overflow-y-auto h-full pr-8'>
                    <div className='sticky bg-white right-0 top-0 float-right pt-2 mb-4 w-full flex justify-between border-b-4 border-box z-[100]'>
                        <div className='text-xl font-medium mb-2'>Pricing Lens Panel</div>
                        <div className='flex gap-4'>
                            <button onClick={() => toggleType('results')} className={hoverStyle+` ${type==="results"&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Standard</button>
                            <button onClick={() => toggleType('target')} className={hoverStyle+` ${type==="target"&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Target</button>
                            <button onClick={() => toggleType('summary')} className={hoverStyle+` ${type==="summary"&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Summary</button>
                            <button onClick={() => toggleType('performance')} className={hoverStyle+` ${type==="performance"&&"border-b-4 mb-[-3px] border-b-light-blue"}`}>Performance</button>
                        </div>
                    </div>
                    <PricingLens switchContext={type} />
                </div>      
            </div>
        </>
    )
};
import {extractFromLocal} from "./loadSave";

export function addEvent(event, currentEvents, addEvents) {
    // Send the new event to the server
    fetch('/api/events/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal().accessToken}`,
        },
        body: JSON.stringify(event)
    })
    .then(response => response.json())
    .then(data => {
        // Check if the request was successful
        if (data.success) {
            const use = data.data
            const payload = {
                id: use.id,
                mag: use.mag,
                modify: use.modify,
                startdate: use.startdate,
                target: use.target,
                etype: use.etype,
                ename: use.ename,
                basis: use.basis,
            }
            addEvents([
                ...currentEvents,
                payload
            ])
        }
    })
    .catch(error => console.error('Error:', error));
};

// Function for loading the events
export function loadEvents(addEvents) {
    fetch(`/api/events/`,
    {       
            headers: { 
                'Content-Type' : 'application/json',
                Authorization : `Bearer ${extractFromLocal()?.accessToken}`,
            }
    })
        .then(response => response.json())
        .then(data => {
            if (data.detail) {
                console.log(data)
            } else {
                addEvents(data)
            }
        }
    );
};

export function deleteEvent(id, addEvents) {
    fetch(`/api/events/${id}`, {method: 'DELETE',
    headers: { 
        'Content-Type' : 'application/json',
        Authorization : `Bearer ${extractFromLocal().accessToken}`,
    }})
    .then(response => {
        if (response.ok) {
            // If the delete was successful, reload the events
            loadEvents(addEvents);
        } else {
            console.error('Error:', response.status);
        }
    });
};

export function convertList(exclusionEvents, contextEvents, set, bool) {

    let dict = {};

    if (exclusionEvents?.length>0) {
        contextEvents.forEach(ele => {
            if (!dict[ele.ename]) {
                if (exclusionEvents.includes(ele.ename)) {
                    dict[ele.ename] = true;
                } else {
                    dict[ele.ename] = false;
                };
            }
        });
    }else {
        contextEvents.forEach(ele => {
            if (!dict[ele.ename]) {
                dict[ele.ename] = bool;
            }
        });
    };
    set(dict);
};

export function convertToArray(list, name, set) {
    let array = []
    if (Object.entries(list).length>0) {
        Object.entries(list).forEach(list => {
            list[1] === true && array.push(list[0])  
        });
    };
    
    if (array.length>0) {
        set(data => ({
            ...data,
            [name]: array
        }))
    };
};

export function handleApplyEvent(e, set, list, lock) {
    const { name } = e.target;

    lock(data => ({
        ...data,
        savedCase: false,
        locked: true,
    }));

    set(appliedList => ({
        ...appliedList,
        [name] : list[name] === true ? false : true
    }));
};

export async function editSingleEvent(payload, id, settings, currentEvents, alterEvents, edit) {

    settings(data => ({
        ...data,
        savedCase: false,
        locked: true,
    }));

    try {

        const data = await fetch(`/api/events/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            },
            body: JSON.stringify(payload)
        });

        await data.json();

        if (data.ok) {
            const current = [...currentEvents];
            const arr = current.map(obj => obj.id === id? payload:obj);
            alterEvents(arr);
            edit({});
        };

    } catch (err) {
        console.log(err)
    }
};
import {extractFromLocal} from "./loadSave";

export function loadRateChart(id, setRatesData) {
    if (!id || id.trim() === "") {
        return; // Exit the function early if ID is null, undefined, or empty.
    }
    fetch(`/api/rateChart/${id}`, 
        {
            headers: {'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal().accessToken}`}
        })
        .then(response => response.json())
        .then(data => {
            return setRatesData(data)
        });
};

export async function saveChanges(formularyName, table, setStatus, setRatesData) {
    // must remove console logs prior to production or will impede on performance - 
    // console.log('--- saveChangesButton clicked ---');
    setStatus("Saving changes, please wait...")

    // Get the entered formulary name
    // let formularyName = document.getElementById('formulary').value;
    // console.log('Entered formulary name:', formularyName);

    // Check if formularyName is blank
    if (!formularyName.trim()) {
        alert('Formulary name cannot be blank');
        return;
    }
    
    // Start an empty array to hold your new rates data
    var newRates = [];
    
    // Get a reference to your rate chart table
    // var table = document.getElementById("rateChart");
    table = table.current

    // console.log('thisistable', table)
    // console.log('Number of rows in rateChart table:', table.rows);

    // Loop through each row in the table
    for (let i = 2; i < table.rows.length-1; i++) {
        // Get a reference to the current row
        var row = table.rows[i];
        
        // Get the values from the cells
        var drugname = row.cells[0].textContent;
        var category = row.cells[1].textContent;
        var mfr = row.cells[2].textContent;
        var newRate = row.cells[5].querySelector('input').value;
        var tpnStatus = row.cells[6].querySelector('select').value;
        

        // Add this row's data to the newRates array
        newRates.push({
            drugname: drugname,
            category: category,
            mfr: mfr,
            newRate: newRate,
            tpnStatus: tpnStatus
        });
    };
    
    // console.log('New rates data to be sent:', newRates);

    // Now send the newRates data to your server using fetch()
    fetch('/api/save_custom_rates/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${extractFromLocal().accessToken}`,
        },
        body: JSON.stringify({
            workingFormularyName: formularyName,
            newRates: newRates
        })
    })
    .then(response => response.json())
    .then(data => {
        // console.log('Server Response:', data);
        if (data.success) {
            loadRateChart(formularyName, setRatesData)
            alert(`Table ${formularyName} saved successfully`);
            setStatus('')
        } else {
            alert('Failed to save changes to custom rates table');
            setStatus('Save failed, please try again')
            setTimeout(() => {
                setStatus('')
            }, 500)
        }
    })
    .catch(error => console.error('Error:', error));
};




export async function addDrugRate (drugName, rate, TPN, category, mfr, formularyId, setStatus, ratesData, setRatesData) {
    setStatus(`Adding new drug ${drugName}`)
     if (drugName && rate && TPN && category && mfr && formularyId) {
        // Build the payload
        const payload = {
            drugname: drugName,
            rate: rate,
            category: category,
            mfr: mfr,
            tpnStatus: TPN,
            formularyid: formularyId
        };
        
        const sucessPayload = {
            drugname: drugName,
            rate: rate,
            category: category,
            mfr: mfr,
            tpn: TPN,
            formularyid: formularyId
        };
        
        try {
            const response = await fetch("/api/addDrugToFormulary", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${extractFromLocal().accessToken}`,
                },
                body: JSON.stringify(payload)
            });
            const result = await response.json();

            if (response.ok) {
                if (result.success) {
                    setStatus(`New drug: ${drugName} has been successfully added`);
                    setRatesData([
                        ...ratesData,
                        sucessPayload
                    ]);
                    alert('Drug added successfully!');
                    setTimeout(() => {
                        setStatus('')
                    }, 500);
                } else {
                    setStatus(`Failed to add drug: ${drugName}, please try again...`)
                    alert('Failed to add drug.');
                    setTimeout(() => {
                        setStatus('')
                    }, 500)
                }
            } else {
                setStatus(`Error: Server responding with status ${response.status}: ${result.detail}`)
                alert(`Server responded with status: ${response.status}. :: ${result.detail}`);
                setTimeout(() => {
                    setStatus('')
                }, 10000)
            };
            
        } catch (error) {
            setStatus(`An error has occurred with the following message ${error}`)
            alert("There was an error:", error);
            setTimeout(() => {
                setStatus('')
            }, 500)
        }

        // Clear the fields after processing - this may no longer be required - paul
        // document.getElementById('addRateDrugName').value = "";
        // document.getElementById('addRateRate').value = "";
        // document.getElementById('addRateCategory').value = "";
        // document.getElementById('addRateMfr').value = "";
        // document.getElementById('addRateTPN').value = "";

    } else {
        alert('Please fill in all fields!');
    };
};

export async function exportRatesFiles(formname) {
    try {
        const data = await fetch(`/api/exportFormularyRateFile/${formname}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });
        const response =await data.json()
        if(data.ok) {
            console.log(response)
        };
        
    } catch (err) {
        console.log("Error:", err)
    };
};

export async function exportRateFileDownload(formname, loading) {
    loading(true);
    try {
        let data = await fetch(`/api/exportFormularyRateFile/${formname}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${extractFromLocal().accessToken}`,
            }
        });

        let response = await data.blob();
        
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = formname+".xlsx";
        link.href = window.URL.createObjectURL(
            new Blob([response]), {type: "application/octet-stream"},
        );
        link.click();
        loading(false);
        
    } catch (err) {
        alert(err);
        loading(false);
        console.log(err);
    };
};